import { put, take } from 'redux-saga/effects';
import { clearAccount } from '../actions';

function* watchClearAccount() {
  while (true) {
    yield take(clearAccount.REQUEST);

    yield put(clearAccount.success());
  }
}

export default watchClearAccount;
