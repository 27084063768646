import { call, put, takeEvery } from 'redux-saga/effects';
import { getEditions } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchGetEditions() {
  yield takeEvery(getEditions.REQUEST, requestGetEditions);
}

function* requestGetEditions() {
  try {
    const { data: editions } = yield call(Api.edition.getAll);

    console.log('requestGetEditions response: ', editions);
    yield put(getEditions.success(editions.results));
  } catch (error) {
    yield put(getEditions.failure());
    yield put(addError(error));
  }
}

export default watchGetEditions;
