/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import globalReducer from './containers/App/reducer';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import loginReducer from './containers/Login/reducer';
import signupPageReducer from './containers/Signup/reducer';
import notificationsReducer from './containers/Notifications/reducer';
import resetReducer from './containers/Reset/reducer';
import usersReducer from './containers/Users/reducer';
import lvUsersReducer from './containers/LvUsers/reducer';
import artworksReducer from './containers/Artworks/reducer';
import artistsReducer from './containers/Artists/reducer';
import editionsReducer from './containers/Editions/reducer';
import clientsReducer from './containers/Clients/reducer';
import accountReducer from './containers/Account/reducer';
import tagsReducer from './containers/TagsAssignment/reducer';
import dashboardReducer from './containers/DashboardPage/reducer';
import clientTagsReducer from './containers/ClientTags/reducer';
import reportsReducer from './containers/Reports/reducer';
import editionTypesReducer from './containers/EditionTypes/reducer';
import mediaTypesReducer from './containers/MediaTypes/reducer';
import clientUsersReducer from './containers/ClientUsers/reducer';
import brandReducer from './containers/Brands/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default (history) =>
  combineReducers({
    global: globalReducer,
    login: loginReducer,
    reset: resetReducer,
    language: languageProviderReducer,
    userSignup: signupPageReducer,
    router: connectRouter(history),
    notifications: notificationsReducer,
    users: usersReducer,
    lvUsers: lvUsersReducer,
    clientUsers: clientUsersReducer,
    artworks: artworksReducer,
    artists: artistsReducer,
    editions: editionsReducer,
    clients: clientsReducer,
    clientTags: clientTagsReducer,
    account: accountReducer,
    tags: tagsReducer,
    dashboard: dashboardReducer,
    reports: reportsReducer,
    editionTypes: editionTypesReducer,
    mediaTypes: mediaTypesReducer,
    brand: brandReducer,
  });
