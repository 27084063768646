import { createSelector } from 'reselect';

const selectAccountDomain = (state) => state.account;

const makeSelectLoading = () =>
  createSelector(selectAccountDomain, (substate) => substate.loading);

const makeSelectAccount = () =>
  createSelector(selectAccountDomain, (substate) => substate.account);

export { makeSelectLoading, makeSelectAccount };
