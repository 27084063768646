import { createSelector } from 'reselect';

/**
 * Direct selector to the Notifications state domain
 */

const selectNotifications = (state) => state.notifications;

/**
 * Default selector used by Notifications
 */
const makeSelectError = () =>
  createSelector(selectNotifications, (substate) => substate.notifications);

const makeSelectOptions = () =>
  createSelector(selectNotifications, (substate) => substate.options);

export { makeSelectError, makeSelectOptions };
