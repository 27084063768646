export const tagsTabs = [
  {
    id: 'tags',
    path: '/tags',
  },
  {
    id: 'tagsAssignment',
    path: '/tags/assignment',
  },
];
