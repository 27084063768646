import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import FontFaceObserver from 'fontfaceobserver';
import 'sanitize.css/sanitize.css';
import './styles/scss/global.css';
// Import Sentry error tracker
import * as Sentry from '@sentry/react';
import { enablePatches, setAutoFreeze } from 'immer';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import App from './containers/App';
import LanguageProvider from './containers/LanguageProvider';
import '!file-loader?name=[name].[ext]!./images/android-icon-36x36.png';
import '!file-loader?name=[name].[ext]!./images/android-icon-48x48.png';
import '!file-loader?name=[name].[ext]!./images/android-icon-72x72.png';
import '!file-loader?name=[name].[ext]!./images/android-icon-96x96.png';
import '!file-loader?name=[name].[ext]!./images/android-icon-144x144.png';
import '!file-loader?name=[name].[ext]!./images/android-icon-192x192.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-57x57.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-60x60.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-72x72.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-76x76.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-114x114.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-120x120.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-144x144.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-152x152.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-180x180.png';
import '!file-loader?name=[name].[ext]!./images/apple-icon-precomposed.png';
import '!file-loader?name=[name].[ext]!./images/favicon-16x16.png';
import '!file-loader?name=[name].[ext]!./images/favicon-32x32.png';
import '!file-loader?name=[name].[ext]!./images/favicon-96x96.png';
import '!file-loader?name=[name].[ext]!./images/ms-icon-70x70.png';
import '!file-loader?name=[name].[ext]!./images/ms-icon-144x144.png';
import '!file-loader?name=[name].[ext]!./images/ms-icon-150x150.png';
import '!file-loader?name=[name].[ext]!./images/ms-icon-310x310.png';
import configureStore from './configureStore';
import history from './utils/history';
import { translationMessages } from './i18n';

setAutoFreeze(false);
enablePatches();
// Setup Sentry error tracker. SENTRY_DSN - env variable. (ref. to webpack.DefinePlugin)
if (process.env.SENTRY_DSN && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
  });
}

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store} context={ReactReduxContext}>
      <LanguageProvider messages={messages}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <ConnectedRouter history={history} context={ReactReduxContext}>
            <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
              <App />
            </Sentry.ErrorBoundary>
          </ConnectedRouter>
        </LocalizationProvider>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', './containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ]),
    ) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch((err) => {
      Sentry.captureException(err);
    });
} else {
  render(translationMessages);
}
