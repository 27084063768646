import { fork, all } from 'redux-saga/effects';
import watchCreateArtist from './sagaWatchers/createArtist';
import watchGetArtist from './sagaWatchers/getArtist';
import watchUpdateArtist from './sagaWatchers/updateArtist';
import watchClearArtist from './sagaWatchers/clearArtist';
import watchDeleteArtists from './sagaWatchers/deleteArtists';
import watchGetTableArtists from './sagaWatchers/getTableArtists';
import watchGetArtists from './sagaWatchers/getArtists';

export default function* defaultSaga() {
  yield all([
    // Artists
    fork(watchGetArtists),
    fork(watchGetTableArtists),
    fork(watchCreateArtist),
    fork(watchGetArtist),
    fork(watchUpdateArtist),
    fork(watchClearArtist),
    fork(watchDeleteArtists),
  ]);
}
