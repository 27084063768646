import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from '../../utils/injectSaga';
// import injectGA from '../../utils/injectGA';
import { checkUser } from '../Login/actions';
import { makeSelectUserInfo } from '../Login/selectors';
import saga from './saga';
import App from './components/App/Loadable';
import { makeSelectOptions } from '../Notifications/selectors';
import { checkBuildNumber } from './actions';

const mapStateToProps = createStructuredSelector({
  userInfo: makeSelectUserInfo(),
  notificationOptions: makeSelectOptions(),
});

const mapDispatchToProps = (dispatch) => ({
  onInitialLoad: () => {
    dispatch(checkUser.request());
  },
  checkRemoteBuildNumber: () =>
    new Promise((resolve) => {
      dispatch(checkBuildNumber.request(resolve));
    }),
});

export default compose(
  injectSaga({ key: 'App', saga }),
  connect(mapStateToProps, mapDispatchToProps),
  // injectGA(), // TODO: Enable GA
)(App);
