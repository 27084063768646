import { call, fork, put, take } from 'redux-saga/effects';
import { createEdition } from '../actions';
import Api from '../../../api/Api';

import { addError } from '../../Notifications/actions';

function* watchCreateEdition() {
  while (true) {
    const {
      payload: { edition, resolve },
    } = yield take(createEdition.REQUEST);

    yield fork(requestCreateEdition, edition, resolve);
  }
}

function* requestCreateEdition({ image, ...edition }, resolve) {
  try {
    const editionNew = {
      ...{ image: image || null },
    };

    Object.keys(edition).forEach((key) => {
      if (
        key !== 'depth_visible' &&
        key !== 'depth_saved' &&
        key !== 'year_visible' &&
        key !== 'year_saved'
      ) {
        editionNew[key] = edition[key];
      }
    });

    const { data: createdEdition } = yield call(Api.edition.post, editionNew);

    resolve();
    console.log('requestCreatedEdition: ', createdEdition);
    yield put(createEdition.success(createdEdition));
  } catch (error) {
    resolve(error.response.data);
    yield put(createEdition.failure());
    yield put(addError(error));
  }
}

export default watchCreateEdition;
