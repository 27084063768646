import { call, put, take, fork } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import { addError } from '../Notifications/actions';
import { login, logout, checkUser } from './actions';
import Api from '../../api/Api';
import watchRefreshToken from './sagaWatchers/refreshToken';

/* eslint-disable no-console */
export function* watchCheckUser() {
  while (true) {
    yield take(checkUser.REQUEST);

    yield fork(requestCheckUser);
  }
}

export function* requestCheckUser() {
  const user = localStorage.getItem('user');
  if (user) {
    const userData = JSON.parse(user);
    userData.permissions = JSON.parse(localStorage.getItem('permissions'));

    yield put(checkUser.success(userData));
  } else {
    yield put(checkUser.failure());
  }
}

export function* watchLogin() {
  while (true) {
    const {
      payload: { values, resolve },
    } = yield take(login.REQUEST);

    yield call(requestLogin, values, resolve);
  }
}

export function* requestLogin(values, resolve) {
  try {
    const {
      data: { token, user, permissions },
      headers: { date },
    } = yield call(Api.auth.login, values);

    resolve();
    console.log('requestLogin response: ', token, user, permissions);

    const decoded = jwt_decode(token);
    const tokenLifetime = decoded.exp * 1000 - (Date.parse(date) || Date.now());

    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('permissions', JSON.stringify(permissions));
    localStorage.setItem('_tokenLifetime', JSON.stringify(tokenLifetime));

    yield put(login.success({ ...user, permissions }));
  } catch (error) {
    if (error.message === 'Network Error') {
      resolve(error);
      yield put(login.failure());
      error.response = { data: [error.message] };
      yield put(addError(error));
    } else {
      resolve(error.response.data);
      yield put(login.failure());
      yield put(addError(error));
    }
  }
}

export function* watchLogout() {
  while (true) {
    yield take(logout.REQUEST);

    yield call(requestLogout);
  }
}

export function* requestLogout() {
  try {
    localStorage.clear();

    yield put(logout.success());
  } catch (error) {
    yield put(logout.failure());
    yield put(addError(error));
  }
}

export default function* defaultSaga() {
  yield fork(watchCheckUser);
  yield fork(watchLogin);
  yield fork(watchLogout);
  yield fork(watchRefreshToken);
}
/* eslint-enable no-console */
