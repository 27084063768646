import request from '../utils/request';
import { API_URL } from '../constants';

export default class Api {
  // User API endpoints
  static auth = {
    login: (data) => request.post(`${API_URL}/api-token-auth/`, data),
    refreshToken: (token) =>
      request.post(`${API_URL}/api-token-refresh/`, { token }),
    resetCheck: (data) =>
      request.post(`${API_URL}/registration/password/reset/`, data),
    resetPassword: (data) =>
      request.post(`${API_URL}/registration/password/reset/confirm/`, data),
    forgotPassword: (data) =>
      request.post(`${API_URL}/registration/password/reset/`, data),
    verifyEmail: (data) =>
      request.post(`${API_URL}/registration/verify-email/`, data),
    resendInvitationEmail: (user) =>
      request.post(`${API_URL}/user/${user.uuid}/resend_confirmation/`),
  };

  static openApiSchema = {
    get: () => request.get(`${API_URL}/schema/`),
  };

  static dashboard = {
    getTagsInventory: () =>
      request.get(`${API_URL}/client-dashboard/tags-inventory/`),
    getVerifiedArtworks: () =>
      request.get(`${API_URL}/client-dashboard/registered-art-pieces/`),
  };

  static reports = {
    get: (data) => request.post(`${API_URL}/quicksight/`, data),
  };

  static assignment = {
    getAll: (params) => request.get(`${API_URL}/assignment/`, { params }),
    get: (id) => request.get(`${API_URL}/assignment/${id}/`),
    post: (assignment) => request.post(`${API_URL}/assignment/`, assignment),
    getEnd: (params) =>
      request.get(`${API_URL}/assignment/get-available-range/`, { params }),
    getInfo: () => request.get(`${API_URL}/tag/inventory/`),
    upload: (file) => request.formData.post(`${API_URL}/tag/upload/`, { file }),
  };

  static batches = {
    getAll: (params) => request.get(`${API_URL}/batch/`, { params }),
  };

  static batchesAvailableRange = {
    get: (params) =>
      request.get(`${API_URL}/batch/get-available-range/`, { params }),
  };

  static account = {
    get: () => request.get(`${API_URL}/account/`),
  };

  // User API endpoints
  static user = {
    getAll: (searchQuery) =>
      request.get(`${API_URL}/client-user/`, {
        params: searchQuery,
      }),
    get: (id) => request.get(`${API_URL}/client-user/${id}/`),
    post: (user) =>
      request.post(`${API_URL}/client-user/`, {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        client: user.client,
        user_type: user.user_type,
      }),
    patch: (user) => request.patch(`${API_URL}/client-user/${user.id}/`, user),
    delete: (id) => request.delete(`${API_URL}/client-user/${id}/`),
    options: () => request.options(`${API_URL}/client-user/`),
    updateImage: (uuid, data) =>
      request.formData.patch(`${API_URL}/client-user/${uuid}/`, data),
  };

  static lvUser = {
    getAll: (searchQuery) =>
      request.get(`${API_URL}/lv-user/`, {
        params: searchQuery,
      }),
    get: (id) => request.get(`${API_URL}/lv-user/${id}/`),
    post: (user) =>
      request.post(`${API_URL}/lv-user/`, {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      }),
    patch: (user) => request.patch(`${API_URL}/lv-user/${user.id}/`, user),
    delete: (id) => request.delete(`${API_URL}/lv-user/${id}/`),
    options: () => request.options(`${API_URL}/lv-user/`),
    updateImage: (uuid, data) =>
      request.formData.patch(`${API_URL}/lv-user/${uuid}/`, data),
  };

  static customerUser = {
    getAll: (searchQuery) =>
      request.get(`${API_URL}/customer-user/`, {
        params: searchQuery,
      }),
  };

  static subscribers = {
    getAll: (searchQuery) =>
      request.get(`${API_URL}/subscribed-customers/`, {
        params: searchQuery,
      }),
    download: () => request.get(`${API_URL}/subscribed-customers/?format=csv`),
  };

  static editionTypes = {
    getAll: (search) =>
      request.get(`${API_URL}/edition-type/`, {
        params: search,
      }),
    getAllTable: (params) =>
      request.get(`${API_URL}/edition-type/`, {
        params,
      }),
    post: (editionType) =>
      request.formData.post(`${API_URL}/edition-type/`, editionType),
    get: (id) => request.get(`${API_URL}/edition-type/${id}/`),
    patch: (editionType) =>
      request.formData.patch(
        `${API_URL}/edition-type/${editionType.id}/`,
        editionType,
      ),
  };

  static mediaTypes = {
    getAll: (search) =>
      request.get(`${API_URL}/mediatype/`, {
        params: search,
      }),
    getAllTable: (params) =>
      request.get(`${API_URL}/mediatype/`, {
        params,
      }),
    post: (mediaType) =>
      request.formData.post(`${API_URL}/mediatype/`, mediaType),
    get: (id) => request.get(`${API_URL}/mediatype/${id}/`),
    patch: (mediaType) =>
      request.formData.patch(
        `${API_URL}/mediatype/${mediaType.id}/`,
        mediaType,
      ),
  };

  static clients = {
    getAll: (params) => request.get(`${API_URL}/client/`, { params }),
    get: (id) => request.get(`${API_URL}/client/${id}/`),
    post: (client) => request.formData.post(`${API_URL}/client/`, client),
    delete: (id) => request.delete(`${API_URL}/client/${id}/`),
    patch: (client) =>
      request.formData.patch(`${API_URL}/client/${client.id}/`, client),
  };

  static brands = {
    get: (id) => request.get(`${API_URL}/client/${id}/`),
    patch: (brand) =>
      request.formData.patch(`${API_URL}/client/${brand.id}/`, brand),
  };

  static edition = {
    getAll: (params) => request.get(`${API_URL}/edition/`, { params }),
    get: (id) => request.get(`${API_URL}/edition/${id}/`),
    post: (edition) => request.formData.post(`${API_URL}/edition/`, edition),
    patch: (edition) =>
      request.formData.patch(`${API_URL}/edition/${edition.id}/`, edition),
    delete: (id) => request.delete(`${API_URL}/edition/${id}/`),
    getAvailablePrintNumbers: (id, params) =>
      request.get(`${API_URL}/edition/${id}/available-print-numbers/`, {
        params,
      }),
  };

  static clientTags = {
    getAll: (params) => request.get(`${API_URL}/client-tag/`, { params }),
    link: (data) => request.post(`${API_URL}/client-tag/link/`, data),
    linkNumbered: (data) =>
      request.post(`${API_URL}/client-tag/link_numbered/`, data),
    unlink: (tag) => request.post(`${API_URL}/client-tag/unlink/`, tag),
  };

  static tagManager = {
    assign: (data) => request.post(`${API_URL}/tag/assign/`, data),
    unregister: (uid, data) =>
      request.post(`${API_URL}/tag-manager/${uid}/unregister-tag/`, data),
    unlink: (tag) => request.post(`${API_URL}/client-tag/unlink/`, tag),
    unassign: (data) => request.post(`${API_URL}/tag/unassign/`, data),
  };

  static tags = {
    getAll: (params) => request.get(`${API_URL}/tag-info/`, { params }),
    patch: (id, docent_only) =>
      request.patch(`${API_URL}/tag-info/${id}/`, { docent_only }),
    getAvailablePrintIds: (params) =>
      request.get(`${API_URL}/client-available-tag/`, {
        params,
      }),
  };

  static remainingTags = {
    post: (values) =>
      request.post(`${API_URL}/edition/total-tags-number-in-range/`, values),
  };

  static artwork = {
    getAll: (params) => request.get(`${API_URL}/artwork/`, { params }),
    get: (id) => request.get(`${API_URL}/artwork/${id}/`),
    post: (artwork) => request.post(`${API_URL}/artwork/`, artwork),
    patch: (artwork) =>
      request.patch(`${API_URL}/artwork/${artwork.id}/`, artwork),
    delete: (id) => request.delete(`${API_URL}/artwork/${id}/`),
  };

  static imports = {
    getAll: (params) => request.get(`${API_URL}/editions/import/`, { params }),
    upload: (file) =>
      request.formData.post(`${API_URL}/editions/import/`, { json_file: file }),
  };

  static artist = {
    getAll: (params) => request.get(`${API_URL}/artist/`, { params }),
    get: (id) => request.get(`${API_URL}/artist/${id}/`),
    post: (artist) => request.formData.post(`${API_URL}/artist/`, artist),
    patch: (artist) =>
      request.formData.patch(`${API_URL}/artist/${artist.id}/`, artist),
    delete: (id) => request.delete(`${API_URL}/artist/${id}/`),
  };

  static checkBuildNumber = {
    get: () => request.getIndexHtml(),
  };
}
