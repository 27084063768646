import { call, fork, put, take } from 'redux-saga/effects';
import { updateLvUser } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchUpdateLvUser() {
  while (true) {
    const {
      payload: { user, resolve },
    } = yield take(updateLvUser.REQUEST);

    yield fork(requestUpdateLvUser, user, resolve);
  }
}

function* requestUpdateLvUser(user, resolve) {
  try {
    const { data: updatedUser } = yield call(Api.lvUser.patch, user);

    resolve();
    console.log('requestUpdateLvUser: ', updatedUser);
    yield put(updateLvUser.success(updatedUser));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateLvUser.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchUpdateLvUser;
