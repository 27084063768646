import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getTableAvailableTags } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';
import { setQueryStatus, setQueryParams } from '../../App/actions';
import { makeSelectQuery } from '../../App/selectors';

const columns = {
  print_id: 'print_id',
};

/* eslint-disable no-console */
function* watchGetTableAvailableTags() {
  yield takeEvery(getTableAvailableTags.REQUEST, requestGetTableAvailableTags);
}

function* requestGetTableAvailableTags({
  payload: { query, resolve, reject },
}) {
  try {
    // const stateQuery = yield select(makeSelectQuery());

    // if (stateQuery.isUse && Object.keys(stateQuery.params).length !== 0) {
    //   query = stateQuery.params;
    //   yield put(setQueryStatus.request(false));
    // } else if (setQuery) {
    //   yield put(setQueryParams.request(query));
    // }

    const params = getParamsFromQuery(query, columns);

    const { data: tags } = yield call(Api.tags.getAll, {
      ...params,
      client__isnull: true,
    });

    resolve({
      data: tags.results,
      page: query.page,
      totalCount: tags.count,
    });
    console.log('requestGetTableAvailableTags response: ', tags);
    yield put(getTableAvailableTags.success(tags.results));
  } catch (error) {
    resolve({
      data: [],
      page: 0,
      totalCount: 0,
    });
    yield put(getTableAvailableTags.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetTableAvailableTags;
