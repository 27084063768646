export const ArtworkDefault = {
  name: '',
  artists: [],
  artists_names: [],
  artists_all: [],
  short_description: '',
  full_description: '',
  shop_link: '',
  inspiration_title: '',
  inspiration: '',
  external_id: '',
  artwork_video: '',
  image: null,
  media_blocks: [],
};
