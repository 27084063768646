import { call, fork, put, take } from 'redux-saga/effects';
import { getBrand, updateBrand } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchUpdateBrand() {
  while (true) {
    const {
      payload: { brand, resolve },
    } = yield take(updateBrand.REQUEST);

    yield fork(requestUpdateBrand, brand, resolve);
  }
}

function* requestUpdateBrand(
  { logo, id, logo_display_artwork, ...brand },
  resolve,
) {
  try {
    const brandNew = {
      ...((logo && typeof logo === 'object') || logo === null ? { logo } : {}),
      id,
      logo_display_artwork,
    };

    const { data: updatedBrand } = yield call(Api.brands.patch, brandNew);

    resolve();
    console.log('requestUpdateBrand: ', updatedBrand);
    yield put(updateBrand.success(updatedBrand));
    yield put(getBrand.request());
  } catch (error) {
    resolve(error.response.data);
    yield put(updateBrand.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchUpdateBrand;
