import { call, put, takeEvery } from 'redux-saga/effects';
import { getAvailablePrintNumbers } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';

/* eslint-disable no-console */
function* watchGetAvailablePrintNumbers() {
  yield takeEvery(
    getAvailablePrintNumbers.REQUEST,
    requestGetAvailablePrintNumbers,
  );
}

function* requestGetAvailablePrintNumbers({
  payload: { id, query, resolve, startNumber },
}) {
  try {
    const params = getParamsFromQuery(query);
    if (startNumber) {
      params.start_number = startNumber;
    }

    const { data: printNumbers } = yield call(
      Api.edition.getAvailablePrintNumbers,
      id,
      params,
    );

    resolve({
      data: printNumbers.results,
    });
    console.log('requestGetAvailablePrintNumbers response: ', printNumbers);
    yield put(getAvailablePrintNumbers.success(printNumbers.results));
  } catch (error) {
    resolve(error?.response?.data);
    yield put(getAvailablePrintNumbers.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetAvailablePrintNumbers;
