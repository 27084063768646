import { fork, all } from 'redux-saga/effects';
import watchCreateEdition from './sagaWatchers/createEdition';
import watchGetEdition from './sagaWatchers/getEdition';
import watchClearEdition from './sagaWatchers/clearEdition';
import watchUpdateEdition from './sagaWatchers/updateEdition';
import watchGetEditionTypes from './sagaWatchers/getEditionTypes';
import watchGetEditions from './sagaWatchers/getEditions';
import watchDeleteEditions from './sagaWatchers/deleteEditions';
import watchGetTableEditions from './sagaWatchers/getTableEditions';
import watchGetRemainingTags from './sagaWatchers/getRemainingTags';
import watchLinkTags from './sagaWatchers/linkTags';
import watchGetTableEditionTags from './sagaWatchers/getTableEditionTags';
import watchGetAvailablePrintNumbers from './sagaWatchers/getAvailablePrintNumbers';
import watchLinkTagsNumbered from './sagaWatchers/linkTagsNumbered';

export default function* defaultSaga() {
  yield all([
    // Editions
    fork(watchGetEditions),
    fork(watchGetTableEditions),
    fork(watchClearEdition),
    fork(watchUpdateEdition),
    fork(watchGetEdition),
    fork(watchCreateEdition),
    fork(watchDeleteEditions),
    fork(watchGetRemainingTags),
    fork(watchGetTableEditionTags),
    fork(watchGetAvailablePrintNumbers),
    // Edition Types
    fork(watchGetEditionTypes),
    // Link Tags
    fork(watchLinkTags),
    fork(watchLinkTagsNumbered),
  ]);
}
