import { handleActions } from 'redux-actions';
import { completeSignup } from './actions';
import { setLoading } from '../../utils/immer';

const loginPageReducer = handleActions(
  {
    [completeSignup.REQUEST]: setLoading(true),
    [completeSignup.SUCCESS]: setLoading(false),
    [completeSignup.FAILURE]: setLoading(false),
  },
  { loading: false },
);

export default loginPageReducer;
