import { createRoutine } from 'redux-saga-routines';

export const updateBrand = createRoutine('UPDATE_BRAND', {
  request: (brand, resolve) => ({
    brand,
    resolve,
  }),
  success: (brand) => ({
    brand,
  }),
});

export const getBrand = createRoutine('GET_BRAND', {
  success: (brand) => ({
    brand,
  }),
});
