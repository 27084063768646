import { call, put, takeEvery } from 'redux-saga/effects';
import { downloadSubscribers } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchDownloadSubscribers() {
  yield takeEvery(downloadSubscribers.REQUEST, requestDownloadSubscribers);
}

function* requestDownloadSubscribers() {
  try {
    const response = yield call(Api.subscribers.download);

    const hiddenElement = document.createElement('a');
    hiddenElement.style.display = 'none';
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
      response.data,
    )}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'subscribers.csv';
    hiddenElement.click();
    hiddenElement.remove();

    console.log('requestDownloadSubscribers response: ', response);
  } catch (error) {
    yield put(downloadSubscribers.failure());
    yield put(addError(error));
  }
}

export default watchDownloadSubscribers;
