import { call, put, takeEvery } from 'redux-saga/effects';
import { linkTags } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchLinkTags() {
  yield takeEvery(linkTags.REQUEST, requestLinkTags);
}

function* requestLinkTags({ payload: { values, resolve } }) {
  try {
    const { data } = yield call(Api.clientTags.link, values);
    console.log('requestLinkTags: ', data);

    resolve();

    yield put(linkTags.success());
  } catch (error) {
    const countError = error?.response?.data?.count;
    const count = parseInt(countError, 10);

    if (!Number.isNaN(count)) {
      resolve({
        ...error.response.data,
        count: `${count} tags available from selected "Start Print ID"`,
      });
    } else {
      resolve(error?.response?.data);
    }
    yield put(linkTags.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchLinkTags;
