import { takeEvery, all, put, call } from 'redux-saga/effects';
import { getDashboardInfo } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

export default function* watchGetDashboardInfo() {
  yield takeEvery(getDashboardInfo.REQUEST, requestGetDashboardInfo);
}

function* requestGetDashboardInfo() {
  try {
    const [{ data: tagsInventory }, { data: verifiedArtworks }] = yield all([
      call(Api.dashboard.getTagsInventory),
      call(Api.dashboard.getVerifiedArtworks),
    ]);
    console.log('requestGetDashboardInfo', tagsInventory, verifiedArtworks);
    yield put(getDashboardInfo.success(verifiedArtworks, tagsInventory));
  } catch (error) {
    yield put(addError(error));
  }
}
