import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getTags } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';
import { makeSelectQuery } from '../../App/selectors';
import { setQueryParams, setQueryStatus } from '../../App/actions';

const columns = {
  batch: 'batch__number',
  end_tag_print_id: 'end_tag_print_id',
  start_tag_print_id: 'start_tag_print_id',
  count: 'count',
  'client.name': 'client__name',
  created_at: 'created_at',
};

function* watchGetTags() {
  yield takeEvery(getTags.REQUEST, requestGetTags);
}

function* requestGetTags({ payload: { query, resolve, setQuery } }) {
  try {
    const stateQuery = yield select(makeSelectQuery());

    if (stateQuery.isUse && Object.keys(stateQuery.params).length !== 0) {
      query = stateQuery.params;
      yield put(setQueryStatus.request(false));
    } else if (setQuery) {
      yield put(setQueryParams.request(query));
    }
    const params = getParamsFromQuery(query, columns);

    const { data: tags } = yield call(Api.assignment.getAll, params);

    resolve({
      data: tags.results,
      page: query.page,
      totalCount: tags.count,
    });
    console.log('requestGetTags response: ', tags);
    yield put(getTags.success(tags.results));
  } catch (error) {
    console.log(error);
    resolve(error.response.data);
    yield put(getTags.failure());
    yield put(addError(error));
  }
}

export default watchGetTags;
