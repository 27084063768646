export const ClientDefault = {
  name: '',
  contact_person: '',
  contact_phone: null,
  offers_link: null,
  logo: null,
  about_label: '',
  bio_label: '',
  shop_label: '',
  authentication_text: '',
  authentication_text_2: '',
  launch_button_label: '',
  authenticated_by: '',
  launch_button_url: '',
  call_to_action: '',
  logo_display_artwork: false,
};
