import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  clearTag,
  getTag,
  createTag,
  getTags,
  getTagsInfo,
  getBatches,
  createAssignment,
  getTableClients,
  getTableClientTags,
  getTableAvailableTags,
} from './actions';
import { setLoading } from '../../utils/immer';
import { InfoDefault, TagDefault } from './records';

const tagsReducer = handleActions(
  {
    // GET TAG
    [getTag.REQUEST]: setLoading(true),
    [getTag.SUCCESS]: produce((draft, { payload: { tag } }) => {
      draft.tag = tag;
      draft.loading = false;
    }),
    [getTag.FAILURE]: setLoading(false),
    // GET BATCHES
    // [getBatches.REQUEST]: setLoading(true),
    [getBatches.SUCCESS]: produce((draft, { payload: { batches } }) => {
      draft.batches = batches;
      // draft.loading = false;
    }),
    [getBatches.FAILURE]: setLoading(false),
    // CREATE TAG
    [createTag.REQUEST]: setLoading(true),
    [createTag.SUCCESS]: setLoading(false),
    [createTag.FAILURE]: setLoading(false),
    // CREATE ASSIGNMENT
    [createAssignment.REQUEST]: setLoading(true),
    [createAssignment.SUCCESS]: setLoading(false),
    [createAssignment.FAILURE]: setLoading(false),
    // GET TAGS
    [getTags.SUCCESS]: produce((draft, { payload: { tags } }) => {
      draft.tags = tags;
    }),
    // CLEAR TAG
    [clearTag.SUCCESS]: produce((draft) => {
      draft.tag = TagDefault;
    }),
    [getTagsInfo.SUCCESS]: produce((draft, { payload: { info } }) => {
      draft.info = info;
    }),
    // GET TABLE CLIENTS
    [getTableClients.REQUEST]: setLoading(true),
    [getTableClients.SUCCESS]: produce((draft, { payload: { clients } }) => {
      draft.clients = clients;
      draft.loading = false;
    }),
    [getTableClients.FAILURE]: setLoading(false),
    // GET TABLE CLIENT TAGS
    [getTableClientTags.REQUEST]: setLoading(true),
    [getTableClientTags.SUCCESS]: produce((draft, { payload: { tags } }) => {
      draft.clientTags = tags;
      draft.loading = false;
    }),
    [getTableClientTags.FAILURE]: setLoading(false),
    // GET TABLE AVAILABLE TAGS
    [getTableAvailableTags.REQUEST]: setLoading(true),
    [getTableAvailableTags.SUCCESS]: produce((draft, { payload: { tags } }) => {
      draft.tags = tags;
      draft.loading = false;
    }),
    [getTableAvailableTags.FAILURE]: setLoading(false),
  },
  {
    tag: TagDefault,
    info: InfoDefault,
    clients: [],
    tags: [],
    clientTags: [],
    batches: [],
    loading: false,
  },
);

export default tagsReducer;
