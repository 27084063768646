import { handleActions } from 'redux-actions';
import produce from 'immer';
import { getDashboardInfo } from './actions';
import { setLoading } from '../../utils/immer';
import { TagsInventoryDefault, VerifiedArtworksDefault } from './records';

const dashboardReducer = handleActions(
  {
    [getDashboardInfo.REQUEST]: setLoading(true),
    [getDashboardInfo.SUCCESS]: produce(
      (draft, { payload: { verifiedArtworks, tagsInventory } }) => {
        draft.verifiedArtworks = verifiedArtworks;
        draft.tagsInventory = tagsInventory;
        draft.loading = false;
      },
    ),
    [getDashboardInfo.FAILURE]: setLoading(false),
  },
  {
    tagsInventory: TagsInventoryDefault,
    verifiedArtworks: VerifiedArtworksDefault,
    loading: false,
  },
);

export default dashboardReducer;
