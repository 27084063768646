import { call, put, takeEvery } from 'redux-saga/effects';
import { getClients } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchGetClients() {
  yield takeEvery(getClients.REQUEST, requestGetClients);
}

function* requestGetClients() {
  try {
    const { data: clients } = yield call(Api.clients.getAll);

    console.log('requestGetClients response: ', clients);
    yield put(getClients.success(clients.results));
  } catch (error) {
    yield put(getClients.failure());
    yield put(addError(error));
  }
}

export default watchGetClients;
