import { createRoutine } from 'redux-saga-routines';

export const getEditions = createRoutine('GET_EDITIONS', {
  success: (editions) => ({
    editions,
  }),
});

export const getTableEditions = createRoutine('GET_TABLE_EDITIONS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (editions) => ({
    editions,
  }),
});

export const getEditionTypes = createRoutine('GET_EDITION_TYPES', {
  request: (search) => ({
    search,
  }),
  success: (editionTypes) => ({
    editionTypes,
  }),
});

export const getEdition = createRoutine('GET_EDITION', {
  request: (id, resolve) => ({
    id,
    resolve,
  }),
  success: (edition) => ({
    edition,
  }),
});

export const createEdition = createRoutine('CREATE_EDITION', {
  request: (edition, resolve) => ({
    edition,
    resolve,
  }),
  success: (edition) => ({
    edition,
  }),
});

export const updateEdition = createRoutine('UPDATE_EDITION', {
  request: (edition, resolve) => ({
    edition,
    resolve,
  }),
  success: (edition) => ({
    edition,
  }),
});

export const deleteEditions = createRoutine('DELETE_EDITIONS', {
  request: (editions, resolve) => ({
    editions,
    resolve,
  }),
  success: (editions) => ({
    editions,
  }),
});

export const getRemainingTags = createRoutine('EDITION_GET_REMAINING_TAGS', {
  request: (values, resolve) => ({
    values,
    resolve,
  }),
});

export const linkTags = createRoutine('EDITION_LINK_TAGS', {
  request: (values, resolve) => ({
    values,
    resolve,
  }),
});

export const linkTagsNumbered = createRoutine('EDITION_LINK_TAGS_NUMBERED', {
  request: (values, resolve) => ({
    values,
    resolve,
  }),
});

export const getTableEditionTags = createRoutine('GET_TABLE_EDITION_TAGS', {
  request: (query, id, resolve, reject) => ({
    query,
    id,
    resolve,
    reject,
  }),
});

export const clearEdition = createRoutine('CLEAR_EDITION');

export const getAvailablePrintNumbers = createRoutine(
  'GET_AVAILABLE_PRINT_NUMBERS',
  {
    request: (id, query, resolve, startNumber) => ({
      id,
      query,
      resolve,
      startNumber,
    }),
    success: (printNumbers) => ({
      printNumbers,
    }),
  },
);
