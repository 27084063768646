import { put, take } from 'redux-saga/effects';
import { clearToken } from '../actions';

function* watchClearToken() {
  while (true) {
    yield take(clearToken.REQUEST);

    yield put(clearToken.success());
  }
}

export default watchClearToken;
