import { createRoutine } from 'redux-saga-routines';

export const getTag = createRoutine('GET_TAG', {
  request: (id) => ({
    id,
  }),
  success: (tag) => ({
    tag,
  }),
});

export const getTags = createRoutine('GET_TAGS', {
  request: (query, resolve, setQuery) => ({
    query,
    resolve,
    setQuery,
  }),
  success: (tags) => ({
    tags,
  }),
});

export const getTagsInfo = createRoutine('GET_TAGS_INFO', {
  success: (info) => ({
    info,
  }),
});

export const createTag = createRoutine('CREATE_TAG', {
  request: (tag, resolve) => ({
    tag,
    resolve,
  }),
  success: (tag) => ({
    tag,
  }),
});

export const getTagEnd = createRoutine('GET_TAG_END', {
  request: (params, resolve) => ({
    params,
    resolve,
  }),
});

export const uploadTagCSV = createRoutine('UPLOAD_TAG_CSV', {
  request: (file, resolve) => ({
    file,
    resolve,
  }),
  success: (file) => ({
    file,
  }),
});

export const clearTag = createRoutine('CLEAR_TAG');

export const getBatches = createRoutine('GET_BATCHES', {
  request: (search, resolve) => ({
    search,
    resolve,
  }),
  success: (batches) => ({
    batches,
  }),
});

export const getAvailableRange = createRoutine('GET_AVAILABLE_RANGE', {
  request: (batch, count, resolve) => ({
    batch,
    count,
    resolve,
  }),
});

export const createAssignment = createRoutine('CREATE_ASSIGNMENT', {
  request: (assignment, resolve) => ({
    assignment,
    resolve,
  }),
  success: (assignment) => ({
    assignment,
  }),
});

export const getTableClients = createRoutine('ASSIGNMENT_GET_TABLE_CLIENTS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (clients) => ({
    clients,
  }),
});

export const getTableClientTags = createRoutine(
  'ASSIGNMENT_GET_TABLE_CLIENT_TAGS',
  {
    request: (query, id, resolve, reject) => ({
      query,
      id,
      resolve,
      reject,
    }),
    success: (tags) => ({
      tags,
    }),
  },
);

export const assignTags = createRoutine('ASSIGN_TAGS', {
  request: (values, resolve) => ({
    values,
    resolve,
  }),
});

export const getTableAvailableTags = createRoutine(
  'ASSIGNMENT_GET_TABLE_AVAILABLE_TAGS',
  {
    request: (query, resolve, reject) => ({
      query,
      resolve,
      reject,
    }),
    success: (tags) => ({
      tags,
    }),
  },
);

export const unassignTag = createRoutine('UNASSIGN_TAG', {
  request: (tag, resolve) => ({
    tag,
    resolve,
  }),
});

export const unlinkTags = createRoutine('UNLINK_TAGS', {
  request: (tag, resolve) => ({
    tag,
    resolve,
  }),
});

export const updateDocentOnly = createRoutine('UPDATE_DOCENT_ONLY', {
  request: (tag, resolve) => ({
    tag,
    resolve,
  }),
});
