import { createSelector } from 'reselect';

const selectLoginDomain = (state) => state.login;

const makeSelectUserInfo = () =>
  createSelector(selectLoginDomain, (substate) => substate.userInfo);

const makeSelectUserOrganization = () =>
  createSelector(
    selectLoginDomain,
    (substate) => substate.userInfo.organization,
  );

const makeSelectLoading = () =>
  createSelector(selectLoginDomain, (substate) => substate.loading);

export {
  selectLoginDomain,
  makeSelectUserInfo,
  makeSelectUserOrganization,
  makeSelectLoading,
};
