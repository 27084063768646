import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  createArtwork,
  getArtworks,
  getTableArtworks,
  getArtwork,
  getMediaType,
  clearArtwork,
  updateArtwork,
  deleteArtworks,
  getTableImports,
  linkTags,
} from './actions';
import { setLoading } from '../../utils/immer';
import { ArtworkDefault } from './records';

const artworksReducer = handleActions(
  {
    // GET ARTWORKS
    [getArtworks.REQUEST]: setLoading(true),
    [getArtworks.SUCCESS]: produce((draft, { payload: { artworks } }) => {
      draft.artworks = artworks;
      draft.loading = false;
    }),
    [getArtworks.FAILURE]: setLoading(false),
    // GET TABLE ARTWORKS
    [getTableArtworks.REQUEST]: setLoading(true),
    [getTableArtworks.SUCCESS]: produce((draft, { payload: { artworks } }) => {
      draft.artworks = artworks;
      draft.loading = false;
    }),
    [getTableArtworks.FAILURE]: setLoading(false),
    // GET ARTWORK
    [getArtwork.REQUEST]: setLoading(true),
    [getArtwork.SUCCESS]: produce((draft, { payload: { artwork } }) => {
      draft.artwork = artwork;
      draft.artwork.artists_all = artwork.artists;
      draft.artwork.artists_names = artwork.artists.map(
        (artist) => artist.name,
      );
      draft.artwork.artists = artwork.artists.map((artist) => artist.id);
      draft.loading = false;
    }),
    [getArtwork.FAILURE]: setLoading(false),
    // CREATE ARTWORK
    [createArtwork.REQUEST]: setLoading(true),
    [createArtwork.SUCCESS]: setLoading(false),
    [createArtwork.FAILURE]: setLoading(false),
    // UPDATE ARTWORK
    [updateArtwork.REQUEST]: setLoading(true),
    [updateArtwork.SUCCESS]: produce((draft, { payload: { artwork } }) => {
      draft.artwork = artwork;
      draft.artwork.artists_all = artwork.artists;
      draft.artwork.artists_names = artwork.artists.map(
        (artist) => artist.name,
      );
      draft.artwork.artists = artwork.artists.map((artist) => artist.id);
      draft.loading = false;
    }),
    [updateArtwork.FAILURE]: setLoading(false),
    // DELETE ARTWORKS
    [deleteArtworks.REQUEST]: setLoading(true),
    [deleteArtworks.SUCCESS]: setLoading(false),
    [deleteArtworks.FAILURE]: setLoading(false),
    // CLEAR ARTWORK
    [clearArtwork.SUCCESS]: produce((draft) => {
      draft.artwork = ArtworkDefault;
    }),
    // GET MEDIA TYPE
    [getMediaType.REQUEST]: setLoading(true),
    [getMediaType.SUCCESS]: produce((draft, { payload: { mediaType } }) => {
      draft.mediaType = mediaType;
      draft.loading = false;
    }),
    [getMediaType.FAILURE]: setLoading(false),
    // GET TABLE IMPORTS
    [getTableImports.REQUEST]: setLoading(true),
    [getTableImports.SUCCESS]: produce((draft, { payload: { imports } }) => {
      draft.imports = imports;
      draft.loading = false;
    }),
    [getTableImports.FAILURE]: setLoading(false),
    // LINK TAGS
    [linkTags.REQUEST]: setLoading(true),
    [linkTags.SUCCESS]: setLoading(false),
    [linkTags.FAILURE]: setLoading(false),
  },
  {
    artworks: [],
    artwork: ArtworkDefault,
    mediaType: [],
    imports: [],
    loading: false,
  },
);

export default artworksReducer;
