import { put, call, takeLatest } from 'redux-saga/effects';
import { getReportUrl } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchGetReportUrl() {
  yield takeLatest(getReportUrl.REQUEST, requestGetReportUrl);
}

function* requestGetReportUrl({ payload: { name } }) {
  try {
    const { data: reportUrl } = yield call(Api.reports.get, {
      dashboard: name,
    });
    console.log('requestGetReportUrl', reportUrl);
    yield put(getReportUrl.success(reportUrl));
  } catch (error) {
    yield put(getReportUrl.failure());
    yield put(addError(error));
  }
}

export default watchGetReportUrl;
