import { call, put, takeEvery } from 'redux-saga/effects';
import { unlinkClientTags } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchUnlinkClientTags() {
  yield takeEvery(unlinkClientTags.REQUEST, requestUnlinkClientTags);
}

function* requestUnlinkClientTags({
  payload: {
    tag: { print_id },
    resolve,
  },
}) {
  try {
    const response = yield call(Api.clientTags.unlink, {
      count: 1,
      print_id,
    });

    resolve();
    console.log('requestUnlinkClientTags response: ', response);
    yield put(unlinkClientTags.success());
  } catch (error) {
    console.log(error);
    yield put(unlinkClientTags.failure());
    yield put(addError(error));
  }
}

export default watchUnlinkClientTags;
