import { put, take } from 'redux-saga/effects';
import { clearReportUrl } from '../actions';

function* watchClearReportUrl() {
  while (true) {
    yield take(clearReportUrl.REQUEST);

    yield put(clearReportUrl.success());
  }
}

export default watchClearReportUrl;
