import { put, take } from 'redux-saga/effects';
import { clearTag } from '../actions';

function* watchClearTag() {
  while (true) {
    yield take(clearTag.REQUEST);

    yield put(clearTag.success());
  }
}

export default watchClearTag;
