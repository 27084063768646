import { call, put, takeEvery } from 'redux-saga/effects';
import { refreshToken } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchRefreshToken() {
  yield takeEvery(refreshToken.REQUEST, requestRefreshToken);
}

function* requestRefreshToken({ payload: { resolve, reject } }) {
  try {
    const token = localStorage.getItem('token');

    const {
      data: userData,
      headers: { date },
    } = yield call(Api.auth.refreshToken, token);

    if (userData.token) {
      localStorage.setItem('token', userData.token);
    }
    resolve(date);
    console.log('requestRefreshToken: ', userData);
  } catch (error) {
    yield put(addError(error));
    reject();
    localStorage.clear();
    window.location.reload();
  }
}
/* eslint-enable no-console */

export default watchRefreshToken;
