import { call, fork, put, take } from 'redux-saga/effects';
import { createArtwork } from '../actions';
import Api from '../../../api/Api';

import { addError } from '../../Notifications/actions';
import convertToBase64 from '../../../utils/convertToBase64';

function* watchCreateArtwork() {
  while (true) {
    const {
      payload: { artwork, resolve },
    } = yield take(createArtwork.REQUEST);

    yield fork(requestCreateArtwork, artwork, resolve);
  }
}

function* requestCreateArtwork(
  { inspirationBlock, inspirationTitleType, image, media_blocks, ...artwork },
  resolve,
) {
  try {
    let imageBase64 = null;
    if (image && typeof image === 'object') {
      imageBase64 = yield call(convertToBase64, image);
    }

    const artworkNew = {
      name: artwork.name,
      artists: artwork.artists,
      short_description: artwork.short_description,
      full_description: artwork.full_description,
      shop_link: artwork.shop_link,
      external_id: artwork.external_id,
      artwork_video: artwork.artwork_video,
      image: imageBase64,
    };

    if (!!inspirationBlock && inspirationTitleType === 'default') {
      artworkNew.inspiration_block = 'default_title';
      artworkNew.inspiration = artwork.inspiration;
    } else if (!!inspirationBlock && inspirationTitleType === 'custom') {
      artworkNew.inspiration_block = 'custom_title';
      artworkNew.inspiration_title = artwork.inspiration_title;
      artworkNew.inspiration = artwork.inspiration;
    } else if (!inspirationBlock) {
      artworkNew.inspiration_block = 'none';
    }

    if (media_blocks && Array.isArray(media_blocks)) {
      yield call(async () => {
        const mediaBlocksNew = await Promise.all(
          media_blocks.map(async (block) => {
            if (
              block.subheading ||
              block.text ||
              block.video_url ||
              block.image
            ) {
              const { image: blockImage, ...blockNew } = block;
              let blockImageBase64 = null;
              if (blockImage && typeof blockImage === 'object') {
                blockImageBase64 = await convertToBase64(blockImage);
              }
              return {
                ...blockNew,
                ...((blockImage && typeof blockImage === 'object') ||
                blockImage === null
                  ? { image: blockImageBase64 }
                  : {}),
              };
            }
            return null;
          }),
        );
        artworkNew.media_blocks = mediaBlocksNew;
      });
    }

    const { data: createdArtwork } = yield call(Api.artwork.post, artworkNew);

    resolve();
    console.log('requestCreatedArtwork: ', createdArtwork);
    yield put(createArtwork.success(createdArtwork));
  } catch (error) {
    resolve(error.response.data);
    yield put(createArtwork.failure());
    yield put(addError(error));
  }
}

export default watchCreateArtwork;
