import { call, put, takeEvery, all } from 'redux-saga/effects';
import { deleteArtworks } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchDeleteArtworks() {
  yield takeEvery(deleteArtworks.REQUEST, requestDeleteArtworks);
}

function* requestDeleteArtworks({ payload: { artworks, resolve } }) {
  try {
    const response = yield all(
      artworks.map((artwork) => call(Api.artwork.delete, artwork.id)),
    );
    resolve();
    console.log('requestDeleteArtworks response: ', response);
    yield put(deleteArtworks.success(artworks));
  } catch (error) {
    yield put(deleteArtworks.failure());
    yield put(addError(error));
  }
}

export default watchDeleteArtworks;
