import { createSelector } from 'reselect';

const selectResetDomain = (state) => state.reset;

const makeSelectToken = () =>
  createSelector(selectResetDomain, (substate) => substate.token);

const makeSelectLoading = () =>
  createSelector(selectResetDomain, (substate) => substate.loading);

export { selectResetDomain, makeSelectToken, makeSelectLoading };
