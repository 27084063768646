import { handleActions } from 'redux-actions';
import produce from 'immer';
import { checkUser, login, logout } from './actions';
import { DefaultUserInfo } from './records';

const loginReducer = handleActions(
  {
    // LocalStorage user data GET
    [checkUser.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [checkUser.SUCCESS]: produce((draft, { payload: { userInfo } }) => {
      draft.userInfo = userInfo;
      draft.loading = false;
    }),
    [checkUser.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    // Login POST
    [login.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [login.SUCCESS]: produce((draft, { payload: { userInfo } }) => {
      draft.userInfo = userInfo;
      draft.loading = false;
    }),
    [login.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
    // Logout POST
    [logout.REQUEST]: produce((draft) => {
      draft.loading = true;
    }),
    [logout.SUCCESS]: produce((draft) => {
      draft.userInfo = DefaultUserInfo;
      draft.loading = false;
    }),
    [logout.FAILURE]: produce((draft) => {
      draft.loading = false;
    }),
  },
  {
    userInfo: DefaultUserInfo,
    loading: false,
  },
);

export default loginReducer;
