import { call, put, takeEvery, all } from 'redux-saga/effects';
import { deleteEditions } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchDeleteEditions() {
  yield takeEvery(deleteEditions.REQUEST, requestDeleteEditions);
}

function* requestDeleteEditions({ payload: { editions, resolve } }) {
  try {
    const response = yield all(
      editions.map((edition) => call(Api.edition.delete, edition.id)),
    );
    resolve();
    console.log('requestDeleteEditions response: ', response);
    yield put(deleteEditions.success(editions));
  } catch (error) {
    yield put(deleteEditions.failure());
    yield put(addError(error));
  }
}

export default watchDeleteEditions;
