import { all, fork } from 'redux-saga/effects';
import watchLogin from '../Login/saga';
import watchNotifications from '../Notifications/saga';
import watchReset from '../Reset/saga';
import watchArtworks from '../Artworks/saga';
import watchArtists from '../Artists/saga';
import watchEditions from '../Editions/saga';
import watchBrands from '../Brands/saga';
import watchUsers from '../Users/saga';
import watchClientUsers from '../ClientUsers/saga';
import watchLvUsers from '../LvUsers/saga';
import watchClients from '../Clients/saga';
import watchClientTags from '../ClientTags/saga';
import watchEditionTypes from '../EditionTypes/saga';
import watchMediaTypes from '../MediaTypes/saga';
import watchAccount from '../Account/saga';
import watchTagsAssignment from '../TagsAssignment/saga';
import watchDashboard from '../DashboardPage/saga';
import watchReports from '../Reports/saga';
import watchSetQueryParams from './sagaWatchers/setQueryParams';
import watchGetOpenApiSchema from './sagaWatchers/getOpenApiSchema';
import watchCheckBuildNumber from './sagaWatchers/checkBuildNumber';

export default function* defaultSaga() {
  yield all([
    // Login watcher
    fork(watchLogin),
    // Reset watcher
    fork(watchReset),
    // Notifications watcher
    fork(watchNotifications),
    // Artworks watcher
    fork(watchArtworks),
    // Artists watcher
    fork(watchArtists),
    // Editions watcher
    fork(watchEditions),
    // Brands watcher
    fork(watchBrands),
    // Users watcher
    fork(watchUsers),
    // Client Users watcher
    fork(watchClientUsers),
    // LvUsers watcher
    fork(watchLvUsers),
    // Clients watcher
    fork(watchClients),
    // Clients Tags watcher
    fork(watchClientTags),
    // Media Types watcher
    fork(watchMediaTypes),
    // Edition Types watcher
    fork(watchEditionTypes),
    // Account watcher
    fork(watchAccount),
    // TagsAssignment watcher
    fork(watchTagsAssignment),
    // Dashboard watcher
    fork(watchDashboard),
    // Reports watcher
    fork(watchReports),
    // SearchQuery watcher
    fork(watchSetQueryParams),
    // OpenApi Schema watcher
    fork(watchGetOpenApiSchema),
    // Build Number watcher
    fork(watchCheckBuildNumber),
  ]);
}
