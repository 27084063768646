import { fork, all, take, put } from 'redux-saga/effects';
import watchGetTableClientTags from './sagaWatchers/getTableClientTags';
import watchUnlinkClientTags from './sagaWatchers/unlinkClientTags';
import { unlinkClientTags } from './actions';
import { addSuccess } from '../Notifications/actions';
import watchGetAvailablePrintIds from './sagaWatchers/getAvailablePrintIds';

function* watchUnlinkClientTagsSuccess() {
  while (true) {
    yield take(unlinkClientTags.SUCCESS);

    yield put(addSuccess('Tag(s) unlinked successfully'));
  }
}

export default function* defaultSaga() {
  yield all([
    // Client Tags
    fork(watchGetTableClientTags),
    fork(watchUnlinkClientTags),
    fork(watchGetAvailablePrintIds),
    // Custom
    fork(watchUnlinkClientTagsSuccess),
  ]);
}
