import { createRoutine } from 'redux-saga-routines';

export const getLvUsers = createRoutine('GET_LV_USERS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (users) => ({
    users,
  }),
});

export const deleteLvUsers = createRoutine('DELETE_LV_USERS', {
  request: (users, resolve) => ({
    users,
    resolve,
  }),
  success: (users) => ({
    users,
  }),
});

export const getLvUser = createRoutine('GET_LV_USER', {
  request: (id) => ({
    id,
  }),
  success: (user) => ({
    user,
  }),
});

export const createLvUser = createRoutine('CREATE_LV_USER', {
  request: (user, resolve) => ({
    user,
    resolve,
  }),
  success: (user) => ({
    user,
  }),
});

export const updateLvUser = createRoutine('UPDATE_LV_USER', {
  request: (user, resolve) => ({
    user,
    resolve,
  }),
  success: (user) => ({
    user,
  }),
});

export const clearLvUser = createRoutine('CLEAR_LV_USER');
