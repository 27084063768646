import { call, put, takeEvery, all } from 'redux-saga/effects';
import { deleteClients } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchDeleteClients() {
  yield takeEvery(deleteClients.REQUEST, requestDeleteClients);
}

function* requestDeleteClients({ payload: { clients, resolve } }) {
  try {
    const response = yield all(
      clients.map((client) => call(Api.clients.delete, client.id)),
    );

    resolve();
    console.log('requestDeleteClients response: ', response);
    yield put(deleteClients.success(clients));
  } catch (error) {
    yield put(deleteClients.failure());
    yield put(addError(error));
  }
}

export default watchDeleteClients;
