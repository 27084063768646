import { fork, all } from 'redux-saga/effects';
import watchGetArtworks from './sagaWatchers/getArtworks';
import watchCreateArtwork from './sagaWatchers/createArtwork';
import watchGetMediaType from './sagaWatchers/getMediatype';
import watchGetArtwork from './sagaWatchers/getArtwork';
import watchClearArtwork from './sagaWatchers/clearArtwork';
import watchUpdateArtwork from './sagaWatchers/updateArtwork';
import watchDeleteArtworks from './sagaWatchers/deleteArtworks';
import watchGetTableArtworks from './sagaWatchers/getTableArtworks';
import watchGetTableImports from './sagaWatchers/getTableImports';
import watchCreateImport from './sagaWatchers/createImport';
import watchGetRemainingTags from './sagaWatchers/getRemainingTags';
import watchLinkTags from './sagaWatchers/linkTags';
import getTableArtworkTags from './sagaWatchers/getTableArtworkTags';

export default function* defaultSaga() {
  yield all([
    // Artworks
    fork(watchGetArtworks),
    fork(watchGetTableArtworks),
    fork(watchGetArtwork),
    fork(watchCreateArtwork),
    fork(watchGetMediaType),
    fork(watchClearArtwork),
    fork(watchUpdateArtwork),
    fork(watchDeleteArtworks),
    fork(watchGetTableImports),
    fork(watchCreateImport),
    fork(watchGetRemainingTags),
    fork(getTableArtworkTags),
    // Link Tags
    fork(watchLinkTags),
  ]);
}
