import { push } from 'connected-react-router';
import { put, take, fork } from 'redux-saga/effects';
import { addSuccess } from '../Notifications/actions';
import { resetCheck, resetPassword } from './actions';
import watchCheckResetToken from './sagaWatchers/checkResetToken';
import watchResetCheck from './sagaWatchers/resetCheck';
import watchResetPassword from './sagaWatchers/resetPassword';
import watchClearToken from './sagaWatchers/clearToken';

function* watchResetPasswordSuccess() {
  while (true) {
    yield take(resetPassword.SUCCESS);

    yield put(addSuccess('Password changed successfully'));

    yield put(push('/login'));
  }
}

function* watchResetCheckSuccess() {
  while (true) {
    yield take(resetCheck.SUCCESS);

    yield put(addSuccess('Check your email for reset link'));
  }
}

export default function* watchReset() {
  yield fork(watchCheckResetToken);
  yield fork(watchResetCheck);
  yield fork(watchResetPassword);
  yield fork(watchClearToken);
  yield fork(watchResetPasswordSuccess);
  yield fork(watchResetCheckSuccess);
}
