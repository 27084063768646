import { createRoutine } from 'redux-saga-routines';

export const getArtists = createRoutine('GET_ARTISTS', {
  success: (artists) => ({
    artists,
  }),
});

export const getTableArtists = createRoutine('GET_TABLE_ARTISTS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (artists) => ({
    artists,
  }),
});

export const getArtist = createRoutine('GET_ARTIST', {
  request: (id) => ({
    id,
  }),
  success: (artist) => ({
    artist,
  }),
});

export const createArtist = createRoutine('CREATE_ARTIST', {
  request: (artist, resolve) => ({
    artist,
    resolve,
  }),
  success: (artist) => ({
    artist,
  }),
});

export const updateArtist = createRoutine('UPDATE_ARTIST', {
  request: (artist, resolve) => ({
    artist,
    resolve,
  }),
  success: (artist) => ({
    artist,
  }),
});

export const deleteArtists = createRoutine('DELETE_ARTISTS', {
  request: (artists, resolve) => ({
    artists,
    resolve,
  }),
  success: (artists) => ({
    artists,
  }),
});

export const clearArtist = createRoutine('CLEAR_ARTIST');
