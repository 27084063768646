import { put, take } from 'redux-saga/effects';
import { clearClient } from '../actions';

function* watchClearClient() {
  while (true) {
    yield take(clearClient.REQUEST);

    yield put(clearClient.success());
  }
}

export default watchClearClient;
