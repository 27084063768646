import { handleActions } from 'redux-actions';
import produce from 'immer';
import { clearAccount, getAccount } from './actions';
import { setLoading } from '../../utils/immer';
import { AccountDefault } from './records';

const accountReducer = handleActions(
  {
    // GET ACCOUNT
    [getAccount.REQUEST]: setLoading(true),
    [getAccount.SUCCESS]: produce((draft, { payload: { account } }) => {
      draft.account = account;
      draft.loading = false;
    }),
    [getAccount.FAILURE]: setLoading(false),
    // CLEAR ACCOUNT
    [clearAccount.SUCCESS]: produce((draft) => {
      draft.account = AccountDefault;
    }),
  },
  {
    account: AccountDefault,
    loading: false,
  },
);

export default accountReducer;
