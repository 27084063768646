import { call, fork, put, take } from 'redux-saga/effects';
import { createMediaType } from '../actions';
import Api from '../../../api/Api';

import { addError } from '../../Notifications/actions';

function* watchCreateClient() {
  while (true) {
    const {
      payload: { mediaType, resolve },
    } = yield take(createMediaType.REQUEST);
    yield fork(requestCreateClient, mediaType, resolve);
  }
}

function* requestCreateClient(mediaType, resolve) {
  try {
    const { data: createdMediaType } = yield call(
      Api.mediaTypes.post,
      mediaType,
    );

    resolve();
    console.log('requestCreateMediaType: ', createdMediaType);
    yield put(createMediaType.success(createdMediaType));
  } catch (error) {
    resolve(error.response.data);
    yield put(createMediaType.failure());
    yield put(addError(error));
  }
}

export default watchCreateClient;
