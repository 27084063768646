import { put, take } from 'redux-saga/effects';
import { clearArtist } from '../actions';

function* watchClearArtist() {
  while (true) {
    yield take(clearArtist.REQUEST);

    yield put(clearArtist.success());
  }
}

export default watchClearArtist;
