import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const loadingStart = createAction('LOADING_START');
export const loadingEnd = createAction('LOADING_END');
export const setMobileOpen = createRoutine('SET_MOBILE_OPEN', {
  request: (value) => ({ value }),
});
export const setMiniActive = createRoutine('SET_MINI_ACTIVE', {
  request: (value) => ({ value }),
});
export const setBlockRoute = createRoutine('SET_BLOCK_ROUTE', {
  request: (value) => ({ value }),
});
export const setQueryParams = createRoutine('SET_QUERY_PARAMS', {
  request: (params) => ({ params }),
  success: (params) => ({ params }),
});
export const setQueryStatus = createRoutine('SET_QUERY_STATUS', {
  request: (value) => ({ value }),
});

export const getOpenApiSchema = createRoutine('GET_OPENAPI_SCHEMA', {
  request: (resolve) => ({
    resolve,
  }),
  success: (schema) => ({ schema }),
});

export const setFirstRun = createRoutine('SET_FIRST_RUN');

export const checkBuildNumber = createRoutine('CHECK_BUILD_NUMBER', {
  request: (resolve) => ({
    resolve,
  }),
});
