import { call, put, takeEvery } from 'redux-saga/effects';
import { updateDocentOnly } from '../actions';
import { addError } from '../../Notifications/actions';
import Api from '../../../api/Api';

function* watchUpdateDocentOnly() {
  yield takeEvery(updateDocentOnly.REQUEST, requestUpdateDocentOnly);
}

function* requestUpdateDocentOnly({
  payload: {
    tag: { docent_only, id },
    resolve,
  },
}) {
  try {
    const { data: response } = yield call(Api.tags.patch, id, !docent_only);

    resolve();
    console.log('requestUpdateDocentOnly response: ', response);
    yield put(updateDocentOnly.success());
  } catch (error) {
    yield put(updateDocentOnly.failure());
    yield put(addError(error));
  }
}

export default watchUpdateDocentOnly;
