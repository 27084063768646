export const reportsTabs = [
  {
    id: 'reports',
    path: '/reports',
  },
  {
    id: 'reportsInventory',
    path: '/reports/inventory',
  },
  {
    id: 'reportsTagUsage',
    path: '/reports/tag-usage',
  },
  {
    id: 'reportsArtLibrary',
    path: '/reports/art-library',
  },
  {
    id: 'reportsContentUsage',
    path: '/reports/content-usage',
  },
];
