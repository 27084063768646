export const usersTabs = [
  {
    id: 'usersList',
    path: '/users',
  },
  {
    id: 'clientUsersList',
    path: '/users/client-users',
  },
  {
    id: 'clientUsersAdd',
    path: '/users/client-users/new',
  },
  {
    id: 'userView',
    path: '/users/client-users/:user',
  },
  {
    id: 'lvUsersList',
    path: '/users/lv-users',
  },
  {
    id: 'lvUsersAdd',
    path: '/users/lv-users/new',
  },
  {
    id: 'lvUserView',
    path: '/users/lv-users/:lvUser',
  },
  {
    id: 'customersList',
    path: '/users/customers',
  },
  {
    id: 'subscribersList',
    path: '/users/subscribers',
  },
];
