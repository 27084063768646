import { call, put, takeEvery } from 'redux-saga/effects';
import { getArtworks } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchGetArtworks() {
  yield takeEvery(getArtworks.REQUEST, requestGetArtworks);
}

function* requestGetArtworks() {
  try {
    const { data: artworks } = yield call(Api.artwork.getAll);

    console.log('requestGetArtworks response: ', artworks);
    yield put(getArtworks.success(artworks.results));
  } catch (error) {
    yield put(getArtworks.failure());
    yield put(addError(error));
  }
}

export default watchGetArtworks;
