import { call, put } from 'redux-saga/effects';
import shortid from 'shortid';
import Api from '../../../api/Api';
import { addNotification } from '../../Notifications/actions';

export function* unregisterStaffTag(uid, data) {
  try {
    return yield call(Api.tagManager.unregister, uid, data);
  } catch (error) {
    yield put(
      addNotification({
        key: shortid.generate(),
        variant: 'error',
        message: 'Something went wrong during unregister operation.',
      }),
    );
    throw new Error();
  }
}

export function* unlinkStaffTag(data) {
  try {
    return yield call(Api.tagManager.unlink, data);
  } catch (error) {
    yield put(
      addNotification({
        key: shortid.generate(),
        variant: 'error',
        message: 'Something went wrong during unlink operation.',
      }),
    );
    throw new Error();
  }
}

export function* unassignStaffTag(data) {
  try {
    return yield call(Api.tagManager.unassign, data);
  } catch (error) {
    yield put(
      addNotification({
        key: shortid.generate(),
        variant: 'error',
        message: 'Something went wrong during unassign operation.',
      }),
    );
    throw new Error();
  }
}
