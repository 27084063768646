import { put, take } from 'redux-saga/effects';
import { clearLvUser } from '../actions';

function* watchClearLvUser() {
  while (true) {
    yield take(clearLvUser.REQUEST);

    yield put(clearLvUser.success());
  }
}

export default watchClearLvUser;
