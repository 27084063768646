import { put, take } from 'redux-saga/effects';
import { clearEditionType } from '../actions';

function* watchClearEditionType() {
  while (true) {
    yield take(clearEditionType.REQUEST);

    yield put(clearEditionType.success());
  }
}

export default watchClearEditionType;
