import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonAdd from '@material-ui/icons/PersonAdd';
import PersonOutline from '@material-ui/icons/PersonOutline';
import LockOpen from '@material-ui/icons/LockOpen';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import NfcIcon from '@material-ui/icons/Nfc';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
import { People, Image, ViewCarousel, Palette } from '@material-ui/icons';

import { PERMISSIONS } from '../../constants';

import DashboardPage from '../DashboardPage/Loadable';
import Reports from '../Reports/Loadable';
import Artworks from '../Artworks/List/Loadable';
import LoginPage from '../Login/Loadable';
import ResetPage from '../Reset/Loadable';
import SignupPage from '../Signup/Loadable';
import ForbiddenPage from '../Forbidden/Loadable';
import ArtistsList from '../Artists/List/Loadable';
import ArtistsAdd from '../Artists/Add/Loadable';
import ArtistsView from '../Artists/View/Loadable';
import ArtistsEdit from '../Artists/Edit/Loadable';
import EditionsList from '../Editions/List/Loadable';
import EditionsAdd from '../Editions/Add/Loadable';
import EditionsView from '../Editions/View/Loadable';
import EditionsEdit from '../Editions/Edit/Loadable';
import EditionsLinkTags from '../Editions/View/components/LinkTags/Loadable';
import EditionTagsList from '../Editions/View/components/EditionTagsList/Loadable';
import ClientTagsList from '../ClientTags/List/Loadable';
import UsersPage from '../UsersPage/Loadable';
import ClientsAdd from '../Clients/Add/Loadable';
import ClientsList from '../Clients/List/Loadable';
import ClientsEdit from '../Clients/Edit/Loadable';
import ClientsView from '../Clients/View/Loadable';
import TagsAssignment from '../TagsAssignment/List/Loadable';
import ClientTagList from '../TagsAssignment/List/components/ClientTagList/Loadable';
import DefaultPage from '../DefaultPage/Loadable';
import AssignClientTags from '../TagsAssignment/List/components/AssignClientTags/Loadable';
import AssignAvailableTags from '../TagsAssignment/List/components/AssignAvailableTags/Loadable';
import AvailableTagList from '../TagsAssignment/List/components/AvailableTagList/Loadable';
import Settings from '../Settings/Loadable';

export const routes = [
  // Default page for redirecting
  {
    id: 'defaultPage',
    path: '/',
    component: DefaultPage,
    exact: true,
    auth: true,
  },
  // Dashboard
  // {
  //   id: 'dashboard',
  //   path: '/dashboard',
  //   component: DashboardPage,
  //   exact: true,
  //   auth: true,
  //   icon: DashboardIcon,
  //   sidebarLink: '/dashboard',
  //   permissions: [PERMISSIONS.USER.DASHBOARD.VIEW],
  // },
  // Tags
  {
    id: 'tags',
    path: '/tags/:tagsTab?',
    component: TagsAssignment,
    exact: true,
    auth: true,
    icon: NfcIcon,
    sidebarLink: '/tags',
    permissions: [PERMISSIONS.TAG.VIEW],
    // breadcrumbsHidden: true,
  },
  // Reports
  {
    id: 'reports',
    path: '/reports/:reportsTab?',
    component: Reports,
    auth: true,
    icon: AssessmentIcon,
    sidebarLink: '/reports',
    permissions: [
      PERMISSIONS.USER.DASHBOARD.VIEW,
      PERMISSIONS.LV_USER.DASHBOARD.VIEW,
    ],
  },
  // Artists
  {
    id: 'artists',
    path: '/artists',
    component: ArtistsList,
    exact: true,
    auth: true,
    icon: Palette,
    sidebarLink: '/artists',
    permissions: [PERMISSIONS.ARTIST.VIEW],
  },
  {
    id: 'artistsAdd',
    path: '/artists/new',
    component: ArtistsAdd,
    exact: true,
    auth: true,
    icon: Palette,
    permissions: [PERMISSIONS.ARTIST.ADD],
  },
  {
    id: 'artistsView',
    path: '/artists/:artistId',
    component: ArtistsView,
    exact: true,
    auth: true,
    icon: Palette,
    permissions: [PERMISSIONS.ARTIST.VIEW],
  },
  {
    id: 'artistsEdit',
    path: '/artists/:artistId/edit',
    component: ArtistsEdit,
    exact: true,
    auth: true,
    icon: Palette,
    permissions: [PERMISSIONS.ARTIST.CHANGE],
  },
  // Artworks
  {
    id: 'artworksList',
    path: '/artworks/:artworkTab?',
    component: Artworks,
    auth: true,
    icon: Image,
    sidebarLink: '/artworks',
    permissions: [PERMISSIONS.ARTWORK.VIEW],
    // breadcrumbsHidden: true,
  },
  // Editions
  {
    id: 'editions',
    path: '/editions',
    component: EditionsList,
    exact: true,
    auth: true,
    icon: ViewCarousel,
    sidebarLink: '/editions',
    permissions: [PERMISSIONS.EDITION.VIEW],
  },
  {
    id: 'editionsAdd',
    path: '/editions/new',
    component: EditionsAdd,
    exact: true,
    auth: true,
    icon: ViewCarousel,
    permissions: [PERMISSIONS.EDITION.ADD],
  },
  {
    id: 'editionsView',
    path: '/editions/:editionId',
    component: EditionsView,
    exact: true,
    auth: true,
    icon: ViewCarousel,
    permissions: [PERMISSIONS.EDITION.VIEW],
  },
  {
    id: 'editionsEdit',
    path: '/editions/:editionId/edit',
    component: EditionsEdit,
    exact: true,
    auth: true,
    icon: ViewCarousel,
    permissions: [PERMISSIONS.EDITION.CHANGE],
  },
  {
    id: 'editionsLinkTags',
    path: '/editions/:editionId/link-tags',
    component: EditionsLinkTags,
    exact: true,
    auth: true,
    icon: ViewCarousel,
    permissions: [PERMISSIONS.EDITION.VIEW],
  },
  {
    id: 'editionsTagsList',
    path: '/editions/:editionId/tags',
    component: EditionTagsList,
    exact: true,
    auth: true,
    icon: ViewCarousel,
    permissions: [PERMISSIONS.EDITION.VIEW],
  },
  // Client Tags
  {
    id: 'clientTags',
    path: '/client-tags',
    component: ClientTagsList,
    exact: true,
    auth: true,
    icon: NfcIcon,
    sidebarLink: '/client-tags',
    permissions: [PERMISSIONS.CLIENT_TAGS.VIEW],
  },
  {
    id: 'tagsAvailable',
    path: '/tags/assignment/available-tags',
    component: AvailableTagList,
    exact: true,
    auth: true,
    icon: NfcIcon,
    permissions: [PERMISSIONS.TAG.VIEW],
  },
  {
    id: 'tagsClient',
    path: '/tags/assignment/:clientId',
    component: ClientTagList,
    exact: true,
    auth: true,
    icon: NfcIcon,
    permissions: [PERMISSIONS.TAG.VIEW],
  },
  {
    id: 'tagsAvailableAssign',
    path: `/tags/assignment/available-tags/assign-tags`,
    component: AssignAvailableTags,
    exact: true,
    auth: true,
    icon: NfcIcon,
    permissions: [PERMISSIONS.TAG.VIEW],
  },
  {
    id: 'tagsClientAssign',
    path: '/tags/assignment/:clientId/assign-tags',
    component: AssignClientTags,
    exact: true,
    auth: true,
    icon: NfcIcon,
    permissions: [PERMISSIONS.TAG.VIEW],
  },
  // Clients
  {
    id: 'clients',
    path: '/clients',
    component: ClientsList,
    exact: true,
    auth: true,
    icon: BusinessCenterIcon,
    sidebarLink: '/clients',
    permissions: [PERMISSIONS.CLIENT.VIEW],
  },
  {
    id: 'clientsAdd',
    path: '/clients/new',
    component: ClientsAdd,
    exact: true,
    auth: true,
    icon: BusinessCenterIcon,
    permissions: [PERMISSIONS.CLIENT.ADD],
  },
  {
    id: 'clientsView',
    path: '/clients/:clientId/',
    component: ClientsView,
    exact: true,
    auth: true,
    icon: BusinessCenterIcon,
    permissions: [PERMISSIONS.CLIENT.VIEW],
  },
  {
    id: 'clientsEdit',
    path: '/clients/:clientId/edit',
    component: ClientsEdit,
    exact: true,
    auth: true,
    icon: BusinessCenterIcon,
    permissions: [PERMISSIONS.CLIENT.CHANGE],
  },
  // Users
  {
    id: 'usersList',
    path: '/users/:usersTab?',
    component: UsersPage,
    auth: true,
    icon: People,
    sidebarLink: '/users',
    permissions: [
      PERMISSIONS.USERS.CLIENT_ADMIN.VIEW,
      PERMISSIONS.USERS.LV_ADMIN.VIEW,
    ],
    // breadcrumbsHidden: true,
  },
  // Settings
  {
    id: 'settings',
    path: '/settings/:settingsTab?',
    component: Settings,
    auth: true,
    icon: SettingsIcon,
    sidebarLink: '/settings/edition-types',
    permissions: [PERMISSIONS.EDITION_TYPE.VIEW],
    // breadcrumbsHidden: true,
  },
  // Account
  // {
  //   id: 'account',
  //   path: '/account/',
  //   component: AccountView,
  //   exact: true,
  //   auth: true,
  //   icon: PersonOutline,
  //   sidebarLink: '/account',
  // },
  // Login
  {
    id: 'login',
    path: '/login',
    component: LoginPage,
    exact: true,
    auth: false,
    icon: PersonOutline,
  },
  // Sign Up
  // TODO: Need check for work
  {
    id: 'signup',
    path: '/signup',
    component: SignupPage,
    exact: true,
    icon: PersonAdd,
  },
  {
    id: 'signupStep',
    path: '/signup/:step?/:key?',
    component: SignupPage,
    icon: PersonAdd,
  },
  // Forgot Password
  {
    id: 'forgot',
    path: '/forgot',
    component: ResetPage,
    exact: true,
    icon: LockOpen,
  },
  {
    id: 'forgotConfirm',
    path: '/password/reset/:token?',
    component: ResetPage,
    icon: LockOpen,
  },
  // Forbidden
  {
    id: 'forbidden',
    path: '/forbidden',
    component: ForbiddenPage,
    exact: true,
    auth: true,
    icon: LockOpen,
  },
];
