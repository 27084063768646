import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  createMediaType,
  getTableMediaTypes,
  getMediaType,
  clearMediaType,
} from './actions';
import { setLoading } from '../../utils/immer';
import { MediaTypeDefault } from './records';

const mediaTypesReducer = handleActions(
  {
    // CREATE MEDIA TYPE
    [createMediaType.REQUEST]: setLoading(true),
    [createMediaType.SUCCESS]: setLoading(false),
    [createMediaType.FAILURE]: setLoading(false),
    // GET TABLE MEDIA TYPE
    [getTableMediaTypes.REQUEST]: setLoading(true),
    [getTableMediaTypes.SUCCESS]: produce(
      (draft, { payload: { mediaTypes } }) => {
        draft.mediaTypes = mediaTypes;
        draft.loading = false;
      },
    ),
    [getTableMediaTypes.FAILURE]: setLoading(false),
    // GET MEDIA TYPE
    [getMediaType.REQUEST]: setLoading(true),
    [getMediaType.SUCCESS]: produce((draft, { payload: { mediaType } }) => {
      draft.mediaType = mediaType;
      draft.loading = false;
    }),
    [getMediaType.FAILURE]: setLoading(false),
    // CLEAR MEDIA TYPE
    [clearMediaType.SUCCESS]: produce((draft) => {
      draft.mediaType = MediaTypeDefault;
    }),
  },
  {
    mediaTypes: [],
    mediaType: MediaTypeDefault,
    loading: false,
  },
);

export default mediaTypesReducer;
