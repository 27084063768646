import { createRoutine } from 'redux-saga-routines';

export const getArtworks = createRoutine('GET_ARTWORKS', {
  success: (artworks) => ({
    artworks,
  }),
});

export const getTableArtworks = createRoutine('GET_TABLE_ARTWORKS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (artworks) => ({
    artworks,
  }),
});

export const getArtwork = createRoutine('GET_ARTWORK', {
  request: (id) => ({
    id,
  }),
  success: (artwork) => ({
    artwork,
  }),
});

export const deleteArtworks = createRoutine('DELETE_ARTWORKS', {
  request: (artworks, resolve) => ({
    artworks,
    resolve,
  }),
  success: (artworks) => ({
    artworks,
  }),
});

export const getMediaType = createRoutine('GET_MEDIATYPE', {
  request: (search) => ({
    search,
  }),
  success: (mediaType) => ({
    mediaType,
  }),
});

export const createArtwork = createRoutine('CREATE_ARTWORK', {
  request: (artwork, resolve) => ({
    artwork,
    resolve,
  }),
  success: (artwork) => ({
    artwork,
  }),
});

export const updateArtwork = createRoutine('UPDATE_ARTWORK', {
  request: (artwork, resolve) => ({
    artwork,
    resolve,
  }),
  success: (artwork) => ({
    artwork,
  }),
});

export const getTableImports = createRoutine('GET_TABLE_IMPORTS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (imports) => ({
    imports,
  }),
});

export const createImport = createRoutine('CREATE_IMPORT', {
  request: (file, resolve) => ({
    file,
    resolve,
  }),
});

export const clearArtwork = createRoutine('CLEAR_ARTWORK');

export const getRemainingTags = createRoutine('ARTWORK_GET_REMAINING_TAGS', {
  request: (values, resolve) => ({
    values,
    resolve,
  }),
});

export const linkTags = createRoutine('ARTWORK_LINK_TAGS', {
  request: (values, resolve) => ({
    values,
    resolve,
  }),
});

export const getTableArtworkTags = createRoutine('GET_TABLE_ARTWORK_TAGS', {
  request: (query, id, resolve, reject) => ({
    query,
    id,
    resolve,
    reject,
  }),
});
