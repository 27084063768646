import { put, take } from 'redux-saga/effects';
import { clearUser } from '../actions';

function* watchClearUser() {
  while (true) {
    yield take(clearUser.REQUEST);

    yield put(clearUser.success());
  }
}

export default watchClearUser;
