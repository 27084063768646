import { call, put, takeEvery } from 'redux-saga/effects';
import { getAvailablePrintIds } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';

/* eslint-disable no-console */
function* watchGetAvailablePrintIds() {
  yield takeEvery(getAvailablePrintIds.REQUEST, requestGetAvailablePrintIds);
}

function* requestGetAvailablePrintIds({
  payload: { query, resolve, startPrintId },
}) {
  try {
    const params = getParamsFromQuery(query);
    if (startPrintId) {
      params.start_print_id = startPrintId;
    }

    const { data: printIds } = yield call(
      Api.tags.getAvailablePrintIds,
      params,
    );

    resolve({
      data: printIds.results,
    });
    console.log('requestGetAvailablePrintIds response: ', printIds);
    yield put(getAvailablePrintIds.success(printIds.results));
  } catch (error) {
    resolve(error?.response?.data);
    yield put(getAvailablePrintIds.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetAvailablePrintIds;
