import { createRoutine } from 'redux-saga-routines';

export const getReportUrl = createRoutine('GET_REPORT_URL', {
  request: (name) => ({
    name,
  }),
  success: (reportUrl) => ({
    reportUrl,
  }),
});

export const clearReportUrl = createRoutine('CLEAR_REPORT_URL');
