import axios from 'axios';
import setSessionExpiredTime from './setSessionExpiredTime';

const getServerUrl = () => {
  if (process.env.SERVER_URL) {
    return process.env.SERVER_URL;
  }
  return window.location.origin;
};

const refreshAxios = axios.create({
  baseURL: getServerUrl(),
  headers: { 'Content-Type': 'application/json' },
});

const refreshToken = () =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    refreshAxios
      .post(`/api/v1/api-token-refresh/`, {
        token,
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        resolve(response.data.token);
      })
      .catch((error) => {
        reject(error);
      });
  });

/* eslint-disable no-console */
const requestAxios = axios.create({
  baseURL: getServerUrl(),
  headers: { 'Content-Type': 'application/json' },
});

requestAxios.interceptors.request.use(
  async (config) => {
    if (config.url !== '/api/v1/api-token-refresh/') {
      setSessionExpiredTime();
    }
    const token = localStorage.getItem('token');
    if (!token) {
      return config;
    }

    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`,
      },
    };
  },
  (error) => Promise.reject(error),
);

requestAxios.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  (error) => {
    if (error.message === 'Network Error') {
      return Promise.reject(error);
    }

    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (error.config.url === '/api/v1/api-token-refresh/') {
      localStorage.clear();
      window.location.reload();

      return Promise.reject(error);
    }

    return refreshToken()
      .then((token) => {
        const { config } = error;
        config.headers.Authorization = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      })
      .catch(() => {
        localStorage.clear();
        window.location.reload();
      });
  },
);

const formDataAxios = axios.create({
  baseURL: getServerUrl(),
  headers: { 'Content-Type': 'multipart/form-data' },
});

formDataAxios.interceptors.request.use(
  async (config) => {
    setSessionExpiredTime();
    const token = localStorage.getItem('token');
    if (!token) {
      return config;
    }
    if (config.data) {
      const formData = new FormData();
      Object.keys(config.data).map((key) =>
        formData.append(key, config.data[key] === null ? '' : config.data[key]),
      );
      return {
        ...config,
        data: formData,
        headers: {
          ...(config.headers || {}),
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`,
      },
    };
  },
  (error) => Promise.reject(error),
);

formDataAxios.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (error.config.url === '/api/v1/api-token-refresh/') {
      localStorage.clear();
      window.location.reload();

      return Promise.reject(error);
    }

    return refreshToken().then((token) => {
      const { config } = error;
      config.headers.Authorization = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  },
);

const requestIndex = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

requestIndex.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  (error) => Promise.reject(error),
);

export default class AjaxQuery {
  static get(url, options = {}) {
    return requestAxios.get(url, { ...options });
  }

  static post(url, options = {}) {
    return requestAxios.post(url, { ...options });
  }

  static patch(url, options = {}) {
    return requestAxios.patch(url, { ...options });
  }

  static put(url, options = {}) {
    return requestAxios.put(url, { ...options });
  }

  static delete(url, options = {}) {
    return requestAxios.delete(url, { ...options });
  }

  static options(url, options = {}) {
    return requestAxios.options(url, { ...options });
  }

  static formData = {
    post(url, options = {}) {
      return formDataAxios.post(url, { ...options });
    },
    patch(url, options = {}) {
      return formDataAxios.patch(url, { ...options });
    },
  };

  static getIndexHtml() {
    return requestIndex.get('/', { baseURL: '' });
  }
}
/* eslint-enable no-console */
