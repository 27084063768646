import { call, fork, put, take } from 'redux-saga/effects';
import { getAccount } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import { getBrand } from '../../Brands/actions';

/* eslint-disable no-console */
function* watchGetAccount() {
  while (true) {
    yield take(getAccount.REQUEST);

    yield fork(requestGetAccount);
  }
}

function* requestGetAccount() {
  try {
    const { data: account } = yield call(Api.account.get);

    console.log('requestGetAccount: ', account);
    yield put(getAccount.success(account));

    if (account?.client?.id) {
      yield put(getBrand.request());
    }
  } catch (error) {
    yield put(getAccount.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetAccount;
