import { call, fork, put, take, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getBrand } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import { makeSelectAccount } from '../../Account/selectors';

/* eslint-disable no-console */
function* watchGetBrand() {
  while (true) {
    yield take(getBrand.REQUEST);

    yield fork(requestGetBrand);
  }
}

function* requestGetBrand() {
  try {
    const account = yield select(makeSelectAccount());
    let response = {};
    if (account?.client?.id) {
      response = yield call(Api.brands.get, account?.client?.id);
    }
    const brand = response?.data;
    console.log('requestGetBrand: ', brand);
    yield put(getBrand.success(brand));
  } catch (error) {
    yield put(getBrand.failure());
    yield put(addError(error));
    yield put(push('/'));
  }
}
/* eslint-enable no-console */

export default watchGetBrand;
