import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getTableClientTags } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';
import { setQueryStatus, setQueryParams } from '../../App/actions';
import { makeSelectQuery } from '../../App/selectors';

const columns = {
  print_id: 'print_id',
  type: 'type',
  size: 'art_piece__edition__dimensions__height,art_piece__edition__dimensions__width',
  artwork: 'artwork_name',
  edition: 'edition_name_with_number',
  uid: 'uid',
  status: 'status',
};

/* eslint-disable no-console */
function* watchGetTableClientTags() {
  yield takeEvery(getTableClientTags.REQUEST, requestGetTableClientTags);
}

function* requestGetTableClientTags({
  payload: { query, resolve, reject, setQuery },
}) {
  try {
    const stateQuery = yield select(makeSelectQuery());

    if (stateQuery.isUse && Object.keys(stateQuery.params).length !== 0) {
      query = stateQuery.params;
      yield put(setQueryStatus.request(false));
    } else if (setQuery) {
      yield put(setQueryParams.request(query));
    }

    const params = getParamsFromQuery(query, columns);

    const { data: tags } = yield call(Api.clientTags.getAll, params);

    resolve({
      data: tags.results,
      page: query.page,
      totalCount: tags.count,
    });
    console.log('requestGetTableClientTags response: ', tags);
    yield put(getTableClientTags.success(tags.results));
  } catch (error) {
    resolve({
      data: [],
      page: 0,
      totalCount: 0,
    });
    yield put(getTableClientTags.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetTableClientTags;
