import { call, put, takeEvery } from 'redux-saga/effects';
import { unlinkTags } from '../actions';
import { addError } from '../../Notifications/actions';
import { TAGS_STATUSES } from '../../../constants';
import { unlinkStaffTag, unregisterStaffTag } from '../utils/sagaUtils';

function* watchUnlinkTags() {
  yield takeEvery(unlinkTags.REQUEST, requestUnlinkTags);
}

function* requestUnlinkTags({
  payload: {
    tag: {
      print_id,
      client: { id: client },
      uid,
      status,
    },
    resolve,
  },
}) {
  try {
    // We need to unregister tag before next action
    if (status === TAGS_STATUSES.REGISTERED) {
      yield call(unregisterStaffTag, uid, {
        print_id,
        client,
        count: 1,
      });
    }

    const response = yield call(unlinkStaffTag, {
      print_id,
      client,
      count: 1,
    });

    resolve();
    console.log('requestUnlinkTags response: ', response);
    yield put(unlinkTags.success());
  } catch (error) {
    console.log(error);
    yield put(unlinkTags.failure());
    yield put(addError(error));
  }
}

export default watchUnlinkTags;
