import { call, fork, put, take } from 'redux-saga/effects';
import { createImport } from '../actions';
import Api from '../../../api/Api';

import { addError } from '../../Notifications/actions';

function* watchCreateImport() {
  while (true) {
    const {
      payload: { file, resolve },
    } = yield take(createImport.REQUEST);
    yield fork(requestCreateImport, file, resolve);
  }
}

function* requestCreateImport(file, resolve) {
  try {
    console.log('file', file);
    yield call(Api.imports.upload, file);

    resolve();
    yield put(createImport.success());
  } catch (error) {
    resolve(error.response.data);
    yield put(createImport.failure());
    yield put(addError(error));
  }
}

export default watchCreateImport;
