import { handleActions } from 'redux-actions';
import produce from 'immer';
import { getBrand, updateBrand } from './actions';
import { setLoading } from '../../utils/immer';
import { BrandDefault } from './records';

const BrandReducer = handleActions(
  {
    // GET BRAND
    [getBrand.REQUEST]: setLoading(true),
    [getBrand.SUCCESS]: produce((draft, { payload: { brand } }) => {
      draft.brand = brand;
      draft.loading = false;
    }),
    [getBrand.FAILURE]: setLoading(false),
    // UPDATE BRAND
    [updateBrand.REQUEST]: setLoading(true),
    [updateBrand.SUCCESS]: produce((draft, { payload: { brand } }) => {
      draft.brand = brand;
      draft.loading = false;
    }),
    [updateBrand.FAILURE]: setLoading(false),
  },
  {
    brand: BrandDefault,
    loading: true,
  },
);

export default BrandReducer;
