import { fork, all } from 'redux-saga/effects';
import watchCreateUser from './sagaWatchers/createUser';
import watchDeleteUsers from './sagaWatchers/deleteUsers';
import watchGetUser from './sagaWatchers/getUser';
import watchUpdateUser from './sagaWatchers/updateUser';
import watchClearUser from './sagaWatchers/clearUser';
import watchGetCustomerUsers from './sagaWatchers/getCustomerUsers';
import watchGetTableUsers from './sagaWatchers/getTableUsers';
import watchGetUsers from './sagaWatchers/getUsers';
import watchGetSubscribers from './sagaWatchers/getSubscribers';
import watchDownloadSubscribers from './sagaWatchers/downloadSubscribers';

export default function* defaultSaga() {
  yield all([
    // Users
    fork(watchCreateUser),
    fork(watchGetTableUsers),
    fork(watchDeleteUsers),
    fork(watchGetUser),
    fork(watchUpdateUser),
    fork(watchClearUser),
    fork(watchGetCustomerUsers),
    fork(watchGetUsers),
    fork(watchGetSubscribers),
    fork(watchDownloadSubscribers),
  ]);
}
