import { fork, all } from 'redux-saga/effects';
import watchCreateClient from './sagaWatchers/createClient';
import watchGetClients from './sagaWatchers/getClients';
import watchDeleteClients from './sagaWatchers/deleteClients';
import watchGetClient from './sagaWatchers/getClient';
import watchUpdateClient from './sagaWatchers/updateClient';
import watchClearClient from './sagaWatchers/clearClient';
import watchGetTableClients from './sagaWatchers/getTableClients';

export default function* defaultSaga() {
  yield all([
    // Clients
    fork(watchCreateClient),
    fork(watchGetClients),
    fork(watchDeleteClients),
    fork(watchGetClient),
    fork(watchGetTableClients),
    fork(watchUpdateClient),
    fork(watchClearClient),
  ]);
}
