import { call, fork, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getUser } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetUser() {
  while (true) {
    const {
      payload: { id },
    } = yield take(getUser.REQUEST);

    yield fork(requestGetUser, id);
  }
}

function* requestGetUser(id) {
  try {
    const { data: user } = yield call(Api.user.get, id);

    console.log('requestGetUser: ', user);
    yield put(getUser.success(user));
  } catch (error) {
    yield put(getUser.failure());
    yield put(addError(error));
    yield put(push('/'));
  }
}
/* eslint-enable no-console */

export default watchGetUser;
