import { call, fork, put, take } from 'redux-saga/effects';
import { updateUser } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchUpdateUser() {
  while (true) {
    const {
      payload: { user, resolve },
    } = yield take(updateUser.REQUEST);

    yield fork(requestUpdateUser, user, resolve);
  }
}

function* requestUpdateUser(user, resolve) {
  try {
    const { data: updatedUser } = yield call(Api.user.patch, user);

    resolve();
    console.log('requestUpdateUser: ', updatedUser);
    yield put(updateUser.success(updatedUser));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateUser.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchUpdateUser;
