import { call, put, takeEvery } from 'redux-saga/effects';
import { createArtist } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchCreateArtist() {
  yield takeEvery(createArtist.REQUEST, requestCreateArtist);
}

// TODO: Refactor this when backend will be reworked in order to send/receive media blocks as array
function* requestCreateArtist({
  payload: {
    artist: { media, image, ...artist },
    resolve,
  },
}) {
  try {
    const artistNew = {
      ...{ image: image || null },
      ...media.reduce(
        (fields, field, index) => ({
          ...fields,
          [`media_subheading_${index + 1}`]: field.subheading,
          ...(field.image?.name || field.image === null
            ? { [`media_image_${index + 1}`]: field.image || null }
            : {}),
          [`media_text_${index + 1}`]: field.text,
          [`media_video_url_${index + 1}`]: field.video_url,
        }),
        {},
      ),
    };

    Object.keys(artist).forEach((key) => {
      if (
        key !== 'quote_visible' &&
        key !== 'quote_saved' &&
        key !== 'media_subheading_1_visible' &&
        key !== 'media_text_1_visible' &&
        key !== 'media_video_url_1_visible' &&
        key !== 'media_subheading_2_visible' &&
        key !== 'media_text_2_visible' &&
        key !== 'media_video_url_2_visible' &&
        key !== 'media_subheading_1_saved' &&
        key !== 'media_text_1_saved' &&
        key !== 'media_video_url_1_saved' &&
        key !== 'media_subheading_2_saved' &&
        key !== 'media_text_2_saved' &&
        key !== 'media_video_url_2_saved'
      ) {
        artistNew[key] = artist[key];
      }
    });

    const { data: createdArtist } = yield call(Api.artist.post, artistNew);

    resolve();
    console.log('requestCreatedArtist: ', createdArtist);
    yield put(createArtist.success(createdArtist));
  } catch (error) {
    resolve(
      error?.response?.data && {
        ...error.response.data,
        media: [
          {
            subheading: error.response.data.media_subheading_1,
            image: error.response.data.media_image_1,
            text: error.response.data.media_text_1,
            video_url: error.response.data.media_video_url_1,
          },
          {
            subheading: error.response.data.media_subheading_2,
            image: error.response.data.media_image_2,
            text: error.response.data.media_text_2,
            video_url: error.response.data.media_video_url_2,
          },
        ],
      },
    );
    yield put(createArtist.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchCreateArtist;
