import { call, fork, put, take } from 'redux-saga/effects';
import { updateEditionType } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchUpdateEditionType() {
  while (true) {
    const {
      payload: { editionType, resolve },
    } = yield take(updateEditionType.REQUEST);

    yield fork(requestUpdateEditionType, editionType, resolve);
  }
}

function* requestUpdateEditionType(editionType, resolve) {
  try {
    const { data: updatedEditionType } = yield call(
      Api.editionTypes.patch,
      editionType,
    );

    resolve();
    console.log('requestUpdateEditionType: ', updatedEditionType);
    yield put(updateEditionType.success(updatedEditionType));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateEditionType.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchUpdateEditionType;
