import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

/**
 * Syntax sugar for all available snackbars
 */
export const addInfo = createAction('ADD_INFO', (info) => ({ info }));
export const addError = createAction('ADD_ERROR', (error) => ({ error }));
export const addWarning = createAction('ADD_WARNING', (warning) => ({
  warning,
}));
export const addSuccess = createAction('ADD_SUCCESS', (success) => ({
  success,
}));
export const addOptions = createRoutine('ADD_OPTIONS', {
  request: (autoHideDuration, maxSnack) => ({
    autoHideDuration,
    maxSnack,
  }),
  success: (autoHideDuration, maxSnack) => ({
    autoHideDuration,
    maxSnack,
  }),
});

export const clearInfo = createAction('CLEAR_INFO');
export const clearError = createAction('CLEAR_ERROR');
export const clearWarning = createAction('CLEAR_WARNING');
export const clearSuccess = createAction('CLEAR_SUCCESS');
export const clearOptions = createRoutine('CLEAR_OPTIONS');

/**
 * After all they turns into notification
 */
export const addNotification = createAction(
  'ADD_NOTIFICATION',
  (notification) => ({ notification }),
);

export const removeNotification = createAction(
  'REMOVE_NOTIFICATION',
  (key) => ({ key }),
);

export const clearNotification = createRoutine('CLEAR_NOTIFICATION');
