import { call, put, takeEvery } from 'redux-saga/effects';
import { getBatches } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchGetBatches() {
  yield takeEvery(getBatches.REQUEST, requestGetBatches);
}

function* requestGetBatches({ payload: { search, resolve } }) {
  try {
    const params = {};

    if (search && search !== '') {
      params.search = search;
    }

    const { data: batches } = yield call(Api.batches.getAll, params);

    // resolve();
    console.log('requestGetBatches response: ', batches);
    yield put(getBatches.success(batches.results));
  } catch (error) {
    console.log(error);
    resolve(error.response.data);
    yield put(getBatches.failure());
    yield put(addError(error));
  }
}

export default watchGetBatches;
