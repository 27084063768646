export const DefaultUserInfo = {
  uuid: null,
  username: null,
  email: null,
  first_name: null,
  last_name: null,
  groups: [],
  organization: null,
  permissions: [],
  image: null,
};
