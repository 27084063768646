import { put, take } from 'redux-saga/effects';
import { setQueryParams } from '../actions';

/* eslint-disable no-console */
function* watchSetQueryParams() {
  while (true) {
    const {
      payload: { params },
    } = yield take(setQueryParams.REQUEST);

    yield put(setQueryParams.success(params));
  }
}
/* eslint-enable no-console */

export default watchSetQueryParams;
