import { handleActions } from 'redux-actions';
import produce from 'immer';
import { clearReportUrl, getReportUrl } from './actions';
import { setLoading } from '../../utils/immer';

const reportsReducer = handleActions(
  {
    // GET REPORT URL
    [getReportUrl.REQUEST]: setLoading(true),
    [getReportUrl.SUCCESS]: produce((draft, { payload: { reportUrl } }) => {
      draft.reportUrl = reportUrl;
      draft.loading = false;
    }),
    [getReportUrl.FAILURE]: setLoading(false),
    // CLEAR REPORT URL
    [clearReportUrl.SUCCESS]: produce((draft) => {
      draft.reportUrl = {};
    }),
  },
  {
    reportUrl: {},
    loading: false,
  },
);

export default reportsReducer;
