import { put, take } from 'redux-saga/effects';
import { clearArtwork } from '../actions';

function* watchClearArtwork() {
  while (true) {
    yield take(clearArtwork.REQUEST);

    yield put(clearArtwork.success());
  }
}

export default watchClearArtwork;
