import { put, take } from 'redux-saga/effects';
import { clearMediaType } from '../actions';

function* watchClearMediaType() {
  while (true) {
    yield take(clearMediaType.REQUEST);

    yield put(clearMediaType.success());
  }
}

export default watchClearMediaType;
