export const TagsInventoryDefault = {
  used: 0,
  available: 0,
  total: 0,
};

export const VerifiedArtworksDefault = {
  registered: 0,
  unregistered: 0,
  total: 0,
};
