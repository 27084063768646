import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  checkResetToken,
  resetCheck,
  resetPassword,
  clearToken,
} from './actions';
import { setLoading } from '../../utils/immer';

const resetReducer = handleActions(
  {
    // Check token in match params GET
    [checkResetToken.REQUEST]: setLoading(true),
    [checkResetToken.SUCCESS]: produce((draft, { payload: { token } }) => {
      draft.token = token;
      draft.loading = false;
    }),
    [checkResetToken.FAILURE]: setLoading(false),
    // Reset availability check POST
    [resetCheck.REQUEST]: setLoading(true),
    [resetCheck.SUCCESS]: setLoading(false),
    [resetCheck.FAILURE]: setLoading(false),
    // Reset password send POST
    [resetPassword.REQUEST]: setLoading(true),
    [resetPassword.SUCCESS]: setLoading(false),
    [resetPassword.FAILURE]: setLoading(false),
    // Reset password send POST
    [clearToken.SUCCESS]: produce((draft) => {
      draft.token = null;
    }),
  },
  {
    token: null,
    loading: false,
  },
);

export default resetReducer;
