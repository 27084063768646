import { call, fork, put, take } from 'redux-saga/effects';
import { createTag } from '../actions';
import Api from '../../../api/Api';

import { addError } from '../../Notifications/actions';

function* watchCreateTag() {
  while (true) {
    const {
      payload: { tag, resolve },
    } = yield take(createTag.REQUEST);
    yield fork(requestCreateTag, tag, resolve);
  }
}

function* requestCreateTag(tag, resolve) {
  try {
    const { data: createdTag } = yield call(Api.assignment.post, tag);

    resolve();
    console.log('requestCreateTag: ', createdTag);
    yield put(createTag.success(createdTag));
  } catch (error) {
    resolve(error.response.data);
    yield put(createTag.failure());
    yield put(addError(error));
  }
}

export default watchCreateTag;
