import { call, fork, put, take } from 'redux-saga/effects';
import { getTag } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetTag() {
  while (true) {
    const {
      payload: { id },
    } = yield take(getTag.REQUEST);

    yield fork(requestGetTag, id);
  }
}

function* requestGetTag(id) {
  try {
    const { data: client } = yield call(Api.assignment.get, id);

    console.log('requestGetTag: ', client);
    yield put(getTag.success(client));
  } catch (error) {
    yield put(getTag.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetTag;
