import { createRoutine } from 'redux-saga-routines';

export const getTableUsers = createRoutine('GET_TABLE_CLIENT_USERS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (users) => ({
    users,
  }),
});

export const getUsers = createRoutine('GET_CLIENT_USERS', {
  success: (users) => ({
    users,
  }),
});

export const deleteUsers = createRoutine('DELETE_CLIENT_USERS', {
  request: (users, resolve) => ({
    users,
    resolve,
  }),
  success: (users) => ({
    users,
  }),
});

export const getUser = createRoutine('GET_CLIENT_USER', {
  request: (id) => ({
    id,
  }),
  success: (user) => ({
    user,
  }),
});

export const createUser = createRoutine('CREATE_CLIENT_USER', {
  request: (user, resolve) => ({
    user,
    resolve,
  }),
  success: (user) => ({
    user,
  }),
});

export const updateUser = createRoutine('UPDATE_CLIENT_USER', {
  request: (user, resolve) => ({
    user,
    resolve,
  }),
  success: (user) => ({
    user,
  }),
});

export const clearUser = createRoutine('CLEAR_CLIENT_USER');

export const getCustomerUsers = createRoutine('GET_CUSTOMER_CLIENT_USERS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (users) => ({
    users,
  }),
});
