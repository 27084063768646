import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  createEditionType,
  getTableEditionTypes,
  getEditionType,
  clearEditionType,
} from './actions';
import { setLoading } from '../../utils/immer';
import { EditionTypeDefault } from './records';

const editionTypesReducer = handleActions(
  {
    // CREATE EDITION TYPE
    [createEditionType.REQUEST]: setLoading(true),
    [createEditionType.SUCCESS]: setLoading(false),
    [createEditionType.FAILURE]: setLoading(false),
    // GET TABLE EDITION TYPE
    [getTableEditionTypes.REQUEST]: setLoading(true),
    [getTableEditionTypes.SUCCESS]: produce(
      (draft, { payload: { editionTypes } }) => {
        draft.editionTypes = editionTypes;
        draft.loading = false;
      },
    ),
    [getTableEditionTypes.FAILURE]: setLoading(false),
    // GET EDITION TYPE
    [getEditionType.REQUEST]: setLoading(true),
    [getEditionType.SUCCESS]: produce((draft, { payload: { editionType } }) => {
      draft.editionType = editionType;
      draft.loading = false;
    }),
    [getEditionType.FAILURE]: setLoading(false),
    // CLEAR EDITION TYPE
    [clearEditionType.SUCCESS]: produce((draft) => {
      draft.editionType = EditionTypeDefault;
    }),
  },
  {
    editionTypes: [],
    editionType: EditionTypeDefault,
    loading: false,
  },
);

export default editionTypesReducer;
