import { call, fork, put, take } from 'redux-saga/effects';
import { createClient } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchCreateClient() {
  while (true) {
    const {
      payload: { client, resolve },
    } = yield take(createClient.REQUEST);
    yield fork(requestCreateClient, client, resolve);
  }
}

function* requestCreateClient({ logo, callToAction, ...client }, resolve) {
  try {
    const clientNew = {
      ...{ logo: logo || null },
    };

    Object.keys(client).forEach((key) => {
      if (
        key !== 'authentication_text_visible' &&
        key !== 'authentication_text_saved' &&
        key !== 'authentication_text_2_visible' &&
        key !== 'authentication_text_2_saved' &&
        key !== 'about_label_visible' &&
        key !== 'bio_label_visible' &&
        key !== 'shop_label_visible' &&
        key !== 'launch_button_label_visible' &&
        key !== 'authenticated_by_visible' &&
        key !== 'about_label_saved' &&
        key !== 'bio_label_saved' &&
        key !== 'shop_label_saved' &&
        key !== 'launch_button_label_saved' &&
        key !== 'authenticated_by_saved'
      ) {
        clientNew[key] = client[key];
      }
    });

    if (!callToAction) {
      clientNew.call_to_action = '';
    }
    if (!client.about_label_visible) {
      clientNew.about_label = '';
    }
    if (!client.bio_label_visible) {
      clientNew.bio_label = '';
    }
    if (!client.shop_label_visible) {
      clientNew.shop_label = '';
    }
    if (!client.launch_button_label_visible) {
      clientNew.launch_button_label = '';
    }
    if (!client.authenticated_by_visible) {
      clientNew.authenticated_by = '';
    }

    const { data: createdClient } = yield call(Api.clients.post, clientNew);

    resolve();
    console.log('requestCreateClient: ', createdClient);
    yield put(createClient.success(createdClient));
  } catch (error) {
    resolve(error.response.data);
    yield put(createClient.failure());
    yield put(addError(error));
  }
}

export default watchCreateClient;
