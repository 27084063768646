import { call, fork, put, take } from 'redux-saga/effects';
import { updateClient } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchUpdateClient() {
  while (true) {
    const {
      payload: { client, resolve },
    } = yield take(updateClient.REQUEST);

    yield fork(requestUpdateClient, client, resolve);
  }
}

function* requestUpdateClient({ logo, callToAction, ...client }, resolve) {
  try {
    const clientNew = {
      ...((logo && typeof logo === 'object') || logo === null ? { logo } : {}),
    };

    Object.keys(client).forEach((key) => {
      if (
        key !== 'authentication_text_visible' &&
        key !== 'authentication_text_saved' &&
        key !== 'authentication_text_2_visible' &&
        key !== 'authentication_text_2_saved' &&
        key !== 'about_label_visible' &&
        key !== 'bio_label_visible' &&
        key !== 'shop_label_visible' &&
        key !== 'launch_button_label_visible' &&
        key !== 'authenticated_by_visible' &&
        key !== 'about_label_saved' &&
        key !== 'bio_label_saved' &&
        key !== 'shop_label_saved' &&
        key !== 'launch_button_label_saved' &&
        key !== 'authenticated_by_saved'
      ) {
        clientNew[key] = client[key];
      }
    });

    if (!callToAction) {
      clientNew.call_to_action = '';
    }
    if (!client.about_label_visible) {
      clientNew.about_label = '';
    }
    if (!client.bio_label_visible) {
      clientNew.bio_label = '';
    }
    if (!client.shop_label_visible) {
      clientNew.shop_label = '';
    }
    if (!client.launch_button_label_visible) {
      clientNew.launch_button_label = '';
    }
    if (!client.authenticated_by_visible) {
      clientNew.authenticated_by = '';
    }

    const { data: updatedClient } = yield call(Api.clients.patch, clientNew);

    resolve();
    console.log('requestUpdateClient: ', updatedClient);
    yield put(updateClient.success(updatedClient));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateClient.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchUpdateClient;
