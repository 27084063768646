import { call, fork, put, take } from 'redux-saga/effects';
import { updateArtwork } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import convertToBase64 from '../../../utils/convertToBase64';

/* eslint-disable no-console */
function* watchUpdateArtwork() {
  while (true) {
    const {
      payload: { artwork, resolve },
    } = yield take(updateArtwork.REQUEST);

    yield fork(requestUpdateArtwork, artwork, resolve);
  }
}

function* requestUpdateArtwork(
  { inspirationBlock, inspirationTitleType, image, media_blocks, ...artwork },
  resolve,
) {
  try {
    let imageBase64 = null;
    if (image && typeof image === 'object') {
      imageBase64 = yield call(convertToBase64, image);
    }

    const artworkNew = {
      ...((image && typeof image === 'object') || image === null
        ? { image: imageBase64 }
        : {}),
    };

    Object.keys(artwork).forEach((key) => {
      if (
        key !== 'artwork_video_visible' &&
        key !== 'artwork_video_saved' &&
        key !== 'inspiration_block' &&
        key !== 'inspiration_title' &&
        key !== 'inspiration'
      ) {
        artworkNew[key] = artwork[key];
      }
    });

    if (!!inspirationBlock && inspirationTitleType === 'default') {
      artworkNew.inspiration_block = 'default_title';
      artworkNew.inspiration = artwork.inspiration;
    } else if (!!inspirationBlock && inspirationTitleType === 'custom') {
      artworkNew.inspiration_block = 'custom_title';
      artworkNew.inspiration_title = artwork.inspiration_title;
      artworkNew.inspiration = artwork.inspiration;
    } else if (!inspirationBlock) {
      artworkNew.inspiration_block = 'none';
    }

    if (media_blocks && Array.isArray(media_blocks)) {
      yield call(async () => {
        const mediaBlocksNew = await Promise.all(
          media_blocks.map(async (block) => {
            if (
              block.subheading ||
              block.text ||
              block.video_url ||
              block.image
            ) {
              const { image: blockImage, ...blockNew } = block;
              let blockImageBase64 = null;
              if (blockImage && typeof blockImage === 'object') {
                blockImageBase64 = await convertToBase64(blockImage);
              }
              return {
                ...blockNew,
                ...((blockImage && typeof blockImage === 'object') ||
                blockImage === null
                  ? { image: blockImageBase64 }
                  : {}),
              };
            }
            return null;
          }),
        );
        artworkNew.media_blocks = mediaBlocksNew;
      });
    }

    const { data: updatedArtwork } = yield call(Api.artwork.patch, artworkNew);

    resolve();
    console.log('requestUpdateArtwork: ', updatedArtwork);
    yield put(updateArtwork.success(updatedArtwork));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateArtwork.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchUpdateArtwork;
