import { call, put, take } from 'redux-saga/effects';
import { resetCheck } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchResetCheck() {
  while (true) {
    const {
      payload: { email, resolve },
    } = yield take(resetCheck.REQUEST);

    yield call(requestResetCheck, email, resolve);
  }
}

function* requestResetCheck(email, resolve) {
  try {
    const {
      data: { token },
    } = yield call(Api.auth.resetCheck, email);

    resolve();
    console.log('requestResetCheck response: ', token);
    yield put(resetCheck.success(token));
  } catch (error) {
    resolve(error.response.data);
    yield put(resetCheck.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchResetCheck;
