import { call, put, takeEvery } from 'redux-saga/effects';
import { getMediaType } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchGetMediaType() {
  yield takeEvery(getMediaType.REQUEST, requestGetMediaType);
}

function* requestGetMediaType({ payload: { search } }) {
  try {
    const { data: mediaType } = yield call(Api.mediaTypes.getAll, search);

    console.log('requestGetMediaType response: ', mediaType);
    yield put(getMediaType.success(mediaType.results));
  } catch (error) {
    yield put(getMediaType.failure());
    yield put(addError(error));
  }
}

export default watchGetMediaType;
