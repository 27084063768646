import { call, fork, put, take } from 'redux-saga/effects';
import Api from '../../../api/Api';
import { checkBuildNumber } from '../actions';

/* eslint-disable no-console */
function* watchCheckBuildNumber() {
  while (true) {
    const {
      payload: { resolve },
    } = yield take(checkBuildNumber.REQUEST);

    yield fork(requestCheckBuildNumber, resolve);
  }
}

function* requestCheckBuildNumber(resolve) {
  try {
    const data = yield call(Api.checkBuildNumber.get);

    resolve(data);
    console.log('requestCheckBuildNumber: ', data);
    yield put(checkBuildNumber.success());
  } catch (error) {
    yield put(checkBuildNumber.failure());
  }
}
/* eslint-enable no-console */

export default watchCheckBuildNumber;
