import { call, fork, put, take } from 'redux-saga/effects';
import { getTag, getTagsInfo } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetTagsInfo() {
  while (true) {
    yield take(getTagsInfo.REQUEST);

    yield fork(requestGetTagsInfo);
  }
}

function* requestGetTagsInfo() {
  try {
    const { data: info } = yield call(Api.assignment.getInfo);

    console.log('requestGetTagsInfo: ', info);
    yield put(getTagsInfo.success(info));
  } catch (error) {
    yield put(getTag.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetTagsInfo;
