import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  clearArtist,
  createArtist,
  deleteArtists,
  getArtist,
  getArtists,
  getTableArtists,
  updateArtist,
} from './actions';
import { setLoading } from '../../utils/immer';
import { ArtistDefault } from './records';

const artistsReducer = handleActions(
  {
    // GET ARTISTS
    [getArtists.REQUEST]: setLoading(true),
    [getArtists.SUCCESS]: produce((draft, { payload: { artists } }) => {
      draft.artists = artists;
      draft.loading = false;
    }),
    [getArtists.FAILURE]: setLoading(false),
    // GET TABLE ARTISTS
    [getTableArtists.REQUEST]: setLoading(true),
    [getTableArtists.SUCCESS]: produce((draft, { payload: { artists } }) => {
      draft.artists = artists;
      draft.loading = false;
    }),
    [getTableArtists.FAILURE]: setLoading(false),
    // GET ARTIST
    [getArtist.REQUEST]: setLoading(true),
    [getArtist.SUCCESS]: produce((draft, { payload: { artist } }) => {
      draft.artist = artist;
      draft.loading = false;
    }),
    [getArtist.FAILURE]: setLoading(false),
    // CREATE ARTIST
    [createArtist.REQUEST]: setLoading(true),
    [createArtist.SUCCESS]: setLoading(false),
    [createArtist.FAILURE]: setLoading(false),
    // UPDATE ARTIST
    [updateArtist.REQUEST]: setLoading(true),
    [updateArtist.SUCCESS]: produce((draft, { payload: { artist } }) => {
      draft.artist = artist;
      draft.loading = false;
    }),
    [updateArtist.FAILURE]: setLoading(false),
    // DELETE ARTISTS
    [deleteArtists.REQUEST]: setLoading(true),
    [deleteArtists.SUCCESS]: setLoading(false),
    [deleteArtists.FAILURE]: setLoading(false),
    // CLEAR ARTIST
    [clearArtist.SUCCESS]: produce((draft) => {
      draft.artist = ArtistDefault;
    }),
  },
  {
    artists: [],
    loading: false,
    artist: ArtistDefault,
  },
);

export default artistsReducer;
