import { fork, all } from 'redux-saga/effects';
import watchGetTableMediaTypes from './sagaWatchers/getTableMediaTypes';
import watchCreateMediaTypeSuccess from './sagaWatchers/createMediaType';
import watchGetMediaType from './sagaWatchers/getMediaType';
import watchClearMediaType from './sagaWatchers/clearMediaType';
import watchUpdateMediaType from './sagaWatchers/updateMediaType';

export default function* defaultSaga() {
  yield all([
    // Media Types
    fork(watchGetTableMediaTypes),
    fork(watchCreateMediaTypeSuccess),
    fork(watchGetMediaType),
    fork(watchClearMediaType),
    fork(watchUpdateMediaType),
  ]);
}
