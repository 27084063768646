import { call, fork, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import { getArtist } from '../actions';

/* eslint-disable no-console */
function* watchGetArtist() {
  while (true) {
    const {
      payload: { id },
    } = yield take(getArtist.REQUEST);

    yield fork(requestGetArtist, id);
  }
}

function* requestGetArtist(id) {
  try {
    const { data: artist } = yield call(Api.artist.get, id);

    console.log('requestGetArtist: ', artist);
    yield put(getArtist.success(artist));
  } catch (error) {
    yield put(getArtist.failure());
    yield put(addError(error));
    yield put(push('/'));
  }
}
/* eslint-enable no-console */

export default watchGetArtist;
