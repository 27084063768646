import { call, put, takeEvery } from 'redux-saga/effects';
import { assignTags } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchAssignTags() {
  yield takeEvery(assignTags.REQUEST, requestAssignTags);
}

function* requestAssignTags({ payload: { values, resolve } }) {
  try {
    const response = yield call(Api.tagManager.assign, values);
    console.log('requestAssignTags response: ', response);

    resolve();

    yield put(assignTags.success());
  } catch (error) {
    const countError = error?.response?.data?.count;
    const count = parseInt(countError, 10);

    if (!Number.isNaN(count)) {
      resolve({
        ...error.response.data,
        count: `${count} tags available from selected "Start Print ID"`,
      });
    } else {
      resolve(error?.response?.data);
    }
    yield put(assignTags.failure());
    yield put(addError(error));
  }
}

export default watchAssignTags;
