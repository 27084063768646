import { createRoutine } from 'redux-saga-routines';

export const deleteClients = createRoutine('DELETE_CLIENTS', {
  request: (clients, resolve) => ({
    clients,
    resolve,
  }),
  success: (clients) => ({
    clients,
  }),
});

export const getClient = createRoutine('GET_CLIENT', {
  request: (id, resolve) => ({
    id,
    resolve,
  }),
  success: (client) => ({
    client,
  }),
});

export const getTableClients = createRoutine('GET_TABLE_CLIENTS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (clients) => ({
    clients,
  }),
});

export const getClients = createRoutine('GET_CLIENTS', {
  success: (clients) => ({
    clients,
  }),
});

export const createClient = createRoutine('CREATE_CLIENT', {
  request: (client, resolve) => ({
    client,
    resolve,
  }),
  success: (client) => ({
    client,
  }),
});

export const updateClient = createRoutine('UPDATE_CLIENT', {
  request: (client, resolve) => ({
    client,
    resolve,
  }),
  success: (client) => ({
    client,
  }),
});

export const clearClient = createRoutine('CLEAR_CLIENT');
