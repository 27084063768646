import { call, fork, put, take } from 'redux-saga/effects';
import { createAssignment } from '../actions';
import Api from '../../../api/Api';

import { addError } from '../../Notifications/actions';

function* watchCreateAssignment() {
  while (true) {
    const {
      payload: { assignment, resolve },
    } = yield take(createAssignment.REQUEST);
    yield fork(requestCreateAssignment, assignment, resolve);
  }
}

function* requestCreateAssignment(assignment, resolve) {
  try {
    const { data: createdAssignment } = yield call(
      Api.assignment.post,
      assignment,
    );

    resolve();
    console.log('requestCreateAssignment: ', createdAssignment);
    yield put(createAssignment.success(createdAssignment));
  } catch (error) {
    resolve(error.response.data);
    yield put(createAssignment.failure());
    yield put(addError(error));
  }
}

export default watchCreateAssignment;
