import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  getLvUsers,
  getLvUser,
  createLvUser,
  updateLvUser,
  clearLvUser,
  deleteLvUsers,
} from './actions';
import { setLoading } from '../../utils/immer';
import { UserDefault } from './records';

const lvUsersReducer = handleActions(
  {
    // GET USERS
    [getLvUsers.REQUEST]: setLoading(true),
    [getLvUsers.SUCCESS]: produce((draft, { payload: { users } }) => {
      draft.users = users;
      draft.loading = false;
    }),
    [getLvUsers.FAILURE]: setLoading(false),
    // GET USER
    [getLvUser.REQUEST]: setLoading(true),
    [getLvUser.SUCCESS]: produce((draft, { payload: { user } }) => {
      draft.user = user;
      draft.loading = false;
    }),
    [getLvUser.FAILURE]: setLoading(false),
    // CREATE USER
    [createLvUser.REQUEST]: setLoading(true),
    [createLvUser.SUCCESS]: setLoading(false),
    [createLvUser.FAILURE]: setLoading(false),
    // UPDATE USER
    [updateLvUser.REQUEST]: setLoading(true),
    [updateLvUser.SUCCESS]: produce((draft, { payload: { user } }) => {
      draft.user = user;
      draft.loading = false;
    }),
    [updateLvUser.FAILURE]: setLoading(false),
    // DELETE USERS
    [deleteLvUsers.REQUEST]: setLoading(true),
    [deleteLvUsers.SUCCESS]: setLoading(false),
    [deleteLvUsers.FAILURE]: setLoading(false),
    // CLEAR USER
    [clearLvUser.SUCCESS]: produce((draft) => {
      draft.user = UserDefault;
    }),
  },
  {
    users: [],
    user: UserDefault,
    loading: false,
  },
);

export default lvUsersReducer;
