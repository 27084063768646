import { createRoutine } from 'redux-saga-routines';

export const checkUser = createRoutine('CHECK_USER', {
  success: (userInfo) => ({
    userInfo,
  }),
});

export const login = createRoutine('LOGIN', {
  request: (values, resolve) => ({ values, resolve }),
  success: (userInfo) => ({
    userInfo,
  }),
});

export const refreshToken = createRoutine('REFRESH_TOKEN', {
  request: (resolve, reject) => ({
    resolve,
    reject,
  }),
});

export const logout = createRoutine('LOGOUT');
