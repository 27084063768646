import { createRoutine } from 'redux-saga-routines';

export const getTableMediaTypes = createRoutine('GET_TABLE_MEDIA_TYPES', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (mediaTypes) => ({
    mediaTypes,
  }),
});

export const createMediaType = createRoutine('CREATE_MEDIA_TYPE', {
  request: (mediaType, resolve) => ({
    mediaType,
    resolve,
  }),
  success: (mediaType) => ({
    mediaType,
  }),
});

export const getMediaType = createRoutine('GET_MEDIA_TYPE', {
  request: (id) => ({
    id,
  }),
  success: (mediaType) => ({
    mediaType,
  }),
});

export const updateMediaType = createRoutine('UPDATE_MEDIA_TYPE', {
  request: (mediaType, resolve) => ({
    mediaType,
    resolve,
  }),
  success: (mediaType) => ({
    mediaType,
  }),
});

export const clearMediaType = createRoutine('CLEAR_MEDIA_TYPE');
