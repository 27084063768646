import { call, fork, put, take } from 'redux-saga/effects';
import { getTagsInfo, uploadTagCSV } from '../actions';
import Api from '../../../api/Api';

import { addError } from '../../Notifications/actions';

function* watchUploadTagCSV() {
  while (true) {
    const {
      payload: { file, resolve },
    } = yield take(uploadTagCSV.REQUEST);
    yield fork(requestUploadTagCSV, file, resolve);
  }
}

function* requestUploadTagCSV(file, resolve) {
  try {
    console.log('file', file);
    yield call(Api.assignment.upload, file);
    resolve();

    yield put(uploadTagCSV.success(file));
    yield put(getTagsInfo.request());
  } catch (error) {
    console.log(error.response.data);
    resolve(error.response.data);
    yield put(uploadTagCSV.failure());
    yield put(addError(error));
  }
}

export default watchUploadTagCSV;
