import { put, take } from 'redux-saga/effects';
import { clearEdition } from '../actions';

function* watchClearEdition() {
  while (true) {
    yield take(clearEdition.REQUEST);

    yield put(clearEdition.success());
  }
}

export default watchClearEdition;
