import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getTableArtworks } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';
import { makeSelectQuery } from '../../App/selectors';
import { setQueryParams, setQueryStatus } from '../../App/actions';

const columns = {
  id: 'id',
  name: 'name',
  artists: 'artist_name_order',
  total_linked_tags_number: 'total_linked_tags_number',
  registered_number: 'registered_number',
};

function* watchGetTableArtworks() {
  yield takeEvery(getTableArtworks.REQUEST, requestGetTableArtworks);
}

function* requestGetTableArtworks({
  payload: { query, resolve, reject, setQuery },
}) {
  try {
    const stateQuery = yield select(makeSelectQuery());

    if (stateQuery.isUse && Object.keys(stateQuery.params).length !== 0) {
      query = stateQuery.params;
      yield put(setQueryStatus.request(false));
    } else if (setQuery) {
      yield put(setQueryParams.request(query));
    }
    const params = getParamsFromQuery(query, columns);

    const { data: artworks } = yield call(Api.artwork.getAll, params);

    resolve({
      data: artworks.results,
      page: query.page,
      totalCount: artworks.count,
    });
    console.log('requestGetTableArtworks response: ', artworks);
    yield put(getTableArtworks.success(artworks.results));
  } catch (error) {
    resolve({
      data: [],
      page: 0,
      totalCount: 0,
    });
    yield put(getTableArtworks.failure());
    yield put(addError(error));
  }
}

export default watchGetTableArtworks;
