import { call, put, takeEvery } from 'redux-saga/effects';
import { unassignTag } from '../actions';
import { addError } from '../../Notifications/actions';
import { TAGS_STATUSES } from '../../../constants';
import {
  unassignStaffTag,
  unlinkStaffTag,
  unregisterStaffTag,
} from '../utils/sagaUtils';

function* watchUnassignTag() {
  yield takeEvery(unassignTag.REQUEST, requestUnassignTag);
}

function* requestUnassignTag({
  payload: {
    tag: { client, print_id, status, uid },
    resolve,
  },
}) {
  try {
    // We need to unregister tag before next action
    if (status === TAGS_STATUSES.REGISTERED) {
      yield call(unregisterStaffTag, uid, {
        print_id,
        client,
        count: 1,
      });
    }

    // We need to unlink tag before next action
    if (
      status === TAGS_STATUSES.REGISTERED ||
      status === TAGS_STATUSES.LINKED
    ) {
      yield call(unlinkStaffTag, {
        print_id,
        client,
        count: 1,
      });
    }

    const response = yield call(unassignStaffTag, {
      client: client.id,
      print_id,
    });

    resolve();
    console.log('requestUnassignTag response: ', response);
    yield put(unassignTag.success());
  } catch (error) {
    console.log(error);
    yield put(unassignTag.failure());
    yield put(addError(error));
  }
}

export default watchUnassignTag;
