import { call, put, takeEvery } from 'redux-saga/effects';
import { getArtists } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchGetArtists() {
  yield takeEvery(getArtists.REQUEST, requestGetArtists);
}

function* requestGetArtists() {
  try {
    const { data: artists } = yield call(Api.artist.getAll);

    console.log('requestGetArtists response: ', artists);
    yield put(getArtists.success(artists.results));
  } catch (error) {
    yield put(getArtists.failure());
    yield put(addError(error));
  }
}

export default watchGetArtists;
