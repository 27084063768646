import { put, take, fork, all } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import shortid from 'shortid';
import {
  addInfo,
  addError,
  addWarning,
  addSuccess,
  addNotification,
  addOptions,
  clearOptions,
  clearNotification,
} from './actions';

export function* watchInfo() {
  while (true) {
    const {
      payload: { info: message },
    } = yield take(addInfo);

    yield put(
      addNotification({
        key: shortid.generate(),
        variant: 'info',
        message,
      }),
    );
  }
}

function getMessagesFromResponse(data) {
  if (!data) {
    return null;
  }

  if (typeof data === 'object') {
    if (data.non_field_errors) {
      return Array.isArray(data.non_field_errors)
        ? data.non_field_errors
        : [data.non_field_errors];
    }
    if (data.file) {
      return Array.isArray(data.file) ? data.file : [data.file];
    }
    if (data.detail && typeof data.detail === 'string') {
      return [data.detail];
    }
    if (Array.isArray(data)) {
      return data;
    }
    console.log('[NOT_RENDERED_OBJECT_RESPONSE]: ', data);
    return null;
  }

  if (typeof data === 'string') {
    return [data];
  }

  console.log('[NOT_RENDERED_UNKNOWN_RESPONSE]: ', data);
  return null;
}

export function* watchError() {
  while (true) {
    const { payload: error } = yield take(addError);

    if (error?.response) {
      Sentry.captureException(error, {
        level: 'warning',
        extra: {
          error,
          response: error?.response,
          data: error?.response?.data,
          localStorage,
        },
      });
    }

    const messages = getMessagesFromResponse(error?.response?.data);

    // TODO: Check if there is any better solution instead of checking response headers (the main goal here is to prevent html responses rendered in error popups)
    if (
      error?.response?.headers &&
      error?.response?.headers?.['content-type']?.indexOf(
        'application/json',
      ) === -1
    ) {
      yield put(
        addNotification({
          key: shortid.generate(),
          variant: 'error',
          message: 'Something went wrong. Try again later.',
        }),
      );
    } else if (messages) {
      yield all(
        messages.map((message) =>
          put(
            addNotification({
              key: shortid.generate(),
              variant: 'error',
              message,
            }),
          ),
        ),
      );
    }
  }
}

export function* watchWarning() {
  while (true) {
    const {
      payload: { warning: message },
    } = yield take(addWarning);

    yield put(
      addNotification({
        key: shortid.generate(),
        variant: 'warning',
        message,
      }),
    );
  }
}

export function* watchSuccess() {
  while (true) {
    const {
      payload: { success: message },
    } = yield take(addSuccess);

    yield put(
      addNotification({
        key: shortid.generate(),
        variant: 'success',
        message,
      }),
    );
  }
}

export function* watchAddOptions() {
  while (true) {
    const {
      payload: { autoHideDuration, maxSnack },
    } = yield take(addOptions.REQUEST);

    yield put(addOptions.success(autoHideDuration, maxSnack));
  }
}

export function* watchClearOptions() {
  while (true) {
    yield take(clearOptions.REQUEST);

    yield put(clearOptions.success());
  }
}

export function* watchClearNotification() {
  while (true) {
    yield take(clearNotification.REQUEST);

    yield put(clearNotification.success());
  }
}

export default function* defaultSaga() {
  yield all([
    fork(watchInfo),
    fork(watchError),
    fork(watchWarning),
    fork(watchSuccess),
    fork(watchAddOptions),
    fork(watchClearOptions),
    fork(watchClearNotification),
  ]);
}
