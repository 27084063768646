import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  createUser,
  getUsers,
  getTableUsers,
  getUser,
  clearUser,
  updateUser,
  deleteUsers,
} from './actions';
import { setLoading } from '../../utils/immer';
import { UserDefault } from './records';

const clientUsersReducer = handleActions(
  {
    // GET USERS
    [getUsers.REQUEST]: setLoading(true),
    [getUsers.SUCCESS]: produce((draft, { payload: { users } }) => {
      draft.users = users;
      draft.loading = false;
    }),
    [getUsers.FAILURE]: setLoading(false),
    // GET TABLE USERS
    [getTableUsers.REQUEST]: setLoading(true),
    [getTableUsers.SUCCESS]: produce((draft, { payload: { users } }) => {
      draft.users = users;
      draft.loading = false;
    }),
    [getTableUsers.FAILURE]: setLoading(false),
    // GET USER
    [getUser.REQUEST]: setLoading(true),
    [getUser.SUCCESS]: produce((draft, { payload: { user } }) => {
      draft.user = user;
      draft.loading = false;
    }),
    [getUser.FAILURE]: setLoading(false),
    // CREATE USER
    [createUser.REQUEST]: setLoading(true),
    [createUser.SUCCESS]: setLoading(false),
    [createUser.FAILURE]: setLoading(false),
    // UPDATE USER
    [updateUser.REQUEST]: setLoading(true),
    [updateUser.SUCCESS]: produce((draft, { payload: { user } }) => {
      draft.user = user;
      draft.loading = false;
    }),
    [updateUser.FAILURE]: setLoading(false),
    // DELETE USERS
    [deleteUsers.REQUEST]: setLoading(true),
    [deleteUsers.SUCCESS]: setLoading(false),
    [deleteUsers.FAILURE]: setLoading(false),
    // CLEAR USER
    [clearUser.SUCCESS]: produce((draft) => {
      draft.user = UserDefault;
    }),
  },
  {
    users: [],
    subscribers: [],
    user: UserDefault,
    loading: false,
  },
);

export default clientUsersReducer;
