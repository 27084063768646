import { fork, all } from 'redux-saga/effects';
import watchGetTableEditionTypes from './sagaWatchers/getTableEditionTypes';
import watchCreateEditionTypeSuccess from './sagaWatchers/createEditionType';
import watchGetEditionType from './sagaWatchers/getEditionType';
import watchClearEditionType from './sagaWatchers/clearEditionType';
import watchUpdateEditionType from './sagaWatchers/updateEditionType';

export default function* defaultSaga() {
  yield all([
    // Edition Types
    fork(watchGetTableEditionTypes),
    fork(watchCreateEditionTypeSuccess),
    fork(watchGetEditionType),
    fork(watchClearEditionType),
    fork(watchUpdateEditionType),
  ]);
}
