import { call, put, take } from 'redux-saga/effects';
import { checkResetToken, clearToken } from '../actions';
import { logout } from '../../Login/actions';

function* watchCheckResetToken() {
  while (true) {
    const {
      payload: { token },
    } = yield take(checkResetToken.REQUEST);

    yield put(logout.request());

    yield put(clearToken.request());

    yield call(requestCheckResetToken, token);
  }
}

function* requestCheckResetToken(token) {
  if (token) {
    yield put(checkResetToken.success(token));
  } else {
    yield put(checkResetToken.failure());
  }
}

export default watchCheckResetToken;
