export const artworkTabs = [
  {
    id: 'artworksList',
    path: '/artworks',
  },
  {
    id: 'artworksAdd',
    path: '/artworks/new',
  },
  {
    id: 'artworksLinkTags',
    path: '/artworks/:artworkId/link-tags',
  },
  {
    id: 'artworksTags',
    path: '/artworks/:artworkId/tags',
  },
  {
    id: 'importsList',
    path: '/artworks/imports',
  },
  {
    id: 'importsAdd',
    path: '/artworks/imports/new',
  },
];
