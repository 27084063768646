import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getTableUsers } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';
import { makeSelectQuery } from '../../App/selectors';
import { setQueryParams, setQueryStatus } from '../../App/actions';

const columns = {
  email: 'email',
  first_name: 'first_name',
  last_name: 'last_name',
  'client.name': 'client__name',
  user_type: 'user_type',
  is_enabled: 'is_enabled',
};

function* watchGetTableUsers() {
  yield takeEvery(getTableUsers.REQUEST, requestGetTableUsers);
}

function* requestGetTableUsers({
  payload: { query, resolve, reject, setQuery },
}) {
  try {
    const stateQuery = yield select(makeSelectQuery());

    if (stateQuery.isUse && Object.keys(stateQuery.params).length !== 0) {
      query = stateQuery.params;
      yield put(setQueryStatus.request(false));
    } else if (setQuery) {
      yield put(setQueryParams.request(query));
    }
    const params = getParamsFromQuery(query, columns);

    const { data: users } = yield call(Api.user.getAll, params);

    resolve({ data: users.results, page: query.page, totalCount: users.count });
    console.log('requestGetTableUsers response: ', users);
    yield put(getTableUsers.success(users.results));
  } catch (error) {
    resolve({
      data: [],
      page: 0,
      totalCount: 0,
    });
    yield put(getTableUsers.failure());
    yield put(addError(error));
  }
}

export default watchGetTableUsers;
