import { call, fork, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getClient } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetClient() {
  while (true) {
    const {
      payload: { id, resolve },
    } = yield take(getClient.REQUEST);

    yield fork(requestGetClient, id, resolve);
  }
}

function* requestGetClient(id, resolve) {
  try {
    const { data: client } = yield call(Api.clients.get, id);

    if (resolve) {
      resolve(client);
    }
    console.log('requestGetClient: ', client);
    yield put(getClient.success(client));
  } catch (error) {
    yield put(getClient.failure());
    yield put(addError(error));
    yield put(push('/'));
  }
}
/* eslint-enable no-console */

export default watchGetClient;
