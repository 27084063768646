import { fork, all } from 'redux-saga/effects';
import watchClearTag from './sagaWatchers/clearTag';
import watchCreateTag from './sagaWatchers/createTag';
import watchGetTag from './sagaWatchers/getTag';
import watchGetTags from './sagaWatchers/getTags';
import watchGetTagEnd from './sagaWatchers/getTagEnd';
import watchGetTagsInfo from './sagaWatchers/getTagsInfo';
import watchUploadTagCSV from './sagaWatchers/uploadTagCSV';
import watchGetBatches from './sagaWatchers/getBatches';
import watchGetAvailableRange from './sagaWatchers/getAvailableRange';
import watchCreateAssignment from './sagaWatchers/createAssignment';
import watchGetTableClients from './sagaWatchers/getTableClients';
import watchGetTableClientTags from './sagaWatchers/getTableClientTags';
import watchGetTableAvailableTags from './sagaWatchers/getTableAvailableTags';
import watchUnassignTag from './sagaWatchers/unassignTags';
import watchAssignTags from './sagaWatchers/assignTags';
import watchUnlinkTags from './sagaWatchers/unlinkTags';
import watchUpdateDocentOnly from './sagaWatchers/updateDocentOnly';

export default function* defaultSaga() {
  yield all([
    // Clients
    fork(watchClearTag),
    fork(watchCreateTag),
    fork(watchGetTag),
    fork(watchGetTags),
    fork(watchGetTagEnd),
    fork(watchGetTagsInfo),
    fork(watchUploadTagCSV),
    fork(watchGetBatches),
    fork(watchGetAvailableRange),
    fork(watchCreateAssignment),
    fork(watchGetTableClients),
    fork(watchGetTableClientTags),
    fork(watchAssignTags),
    fork(watchUnlinkTags),
    fork(watchUnassignTag),
    fork(watchUpdateDocentOnly),
    fork(watchGetTableAvailableTags),
  ]);
}
