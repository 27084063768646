import { call, put, takeEvery, all } from 'redux-saga/effects';
import { deleteUsers } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchDeleteUsers() {
  yield takeEvery(deleteUsers.REQUEST, requestDeleteUsers);
}

function* requestDeleteUsers({ payload: { users, resolve } }) {
  try {
    const response = yield all(
      users.map((user) => call(Api.user.delete, user.id)),
    );

    resolve();
    console.log('requestDeleteUsers response: ', response);
    yield put(deleteUsers.success(users));
  } catch (error) {
    yield put(deleteUsers.failure());
    yield put(addError(error));
  }
}

export default watchDeleteUsers;
