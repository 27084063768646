import { call, fork, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getArtwork } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetArtwork() {
  while (true) {
    const {
      payload: { id },
    } = yield take(getArtwork.REQUEST);

    yield fork(requestGetArtwork, id);
  }
}

function* requestGetArtwork(id) {
  try {
    const { data: artwork } = yield call(Api.artwork.get, id);

    console.log('requestGetArtwork: ', artwork);
    yield put(getArtwork.success(artwork));
  } catch (error) {
    yield put(getArtwork.failure());
    yield put(addError(error));
    yield put(push('/'));
  }
}
/* eslint-enable no-console */

export default watchGetArtwork;
