import { handleActions } from 'redux-actions';
import produce from 'immer';
import { getTableClientTags } from './actions';
import { setLoading } from '../../utils/immer';

const clientTagsReducer = handleActions(
  {
    // GET TABLE CLIENT TAGS
    [getTableClientTags.REQUEST]: setLoading(true),
    [getTableClientTags.SUCCESS]: produce((draft, { payload: { tags } }) => {
      draft.tags = tags;
      draft.loading = false;
    }),
    [getTableClientTags.FAILURE]: setLoading(false),
  },
  {
    tags: [],
    loading: false,
  },
);

export default clientTagsReducer;
