import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  getEditions,
  getEdition,
  createEdition,
  updateEdition,
  clearEdition,
  getEditionTypes,
  deleteEditions,
  getTableEditions,
  linkTags,
  linkTagsNumbered,
} from './actions';
import { setLoading } from '../../utils/immer';
import { EditionDefault } from './records';

const editionsReducer = handleActions(
  {
    // GET EDITIONS
    [getEditions.REQUEST]: setLoading(true),
    [getEditions.SUCCESS]: produce((draft, { payload: { editions } }) => {
      draft.editions = editions;
      draft.loading = false;
    }),
    [getEditions.FAILURE]: setLoading(false),
    // GET TABLE EDITIONS
    [getTableEditions.REQUEST]: setLoading(true),
    [getTableEditions.SUCCESS]: produce((draft, { payload: { editions } }) => {
      draft.editions = editions;
      draft.loading = false;
    }),
    [getTableEditions.FAILURE]: setLoading(false),
    // GET EDITION
    [getEdition.REQUEST]: setLoading(true),
    [getEdition.SUCCESS]: produce((draft, { payload: { edition } }) => {
      draft.edition = edition;
      draft.loading = false;
    }),
    [getEdition.FAILURE]: setLoading(false),
    // CREATE EDITION
    [createEdition.REQUEST]: setLoading(true),
    [createEdition.SUCCESS]: setLoading(false),
    [createEdition.FAILURE]: setLoading(false),
    // UPDATE EDITION
    [updateEdition.REQUEST]: setLoading(true),
    [updateEdition.SUCCESS]: produce((draft, { payload: { edition } }) => {
      draft.edition = edition;
      draft.loading = false;
    }),
    [updateEdition.FAILURE]: setLoading(false),
    // DELETE EDITIONS
    [deleteEditions.REQUEST]: setLoading(true),
    [deleteEditions.SUCCESS]: setLoading(false),
    [deleteEditions.FAILURE]: setLoading(false),
    // CLEAR EDITION
    [clearEdition.SUCCESS]: produce((draft) => {
      draft.edition = EditionDefault;
    }),
    // GET EDITION TYPES
    // [getEditionTypes.REQUEST]: setLoading(true),
    [getEditionTypes.SUCCESS]: produce(
      (draft, { payload: { editionTypes } }) => {
        draft.editionTypes = editionTypes;
        // draft.loading = false;
      },
    ),
    // [getEditionTypes.FAILURE]: setLoading(false),
    // LINK TAGS
    [linkTags.REQUEST]: setLoading(true),
    [linkTags.SUCCESS]: setLoading(false),
    [linkTags.FAILURE]: setLoading(false),
    // LINK TAGS NUMBERED
    [linkTagsNumbered.REQUEST]: setLoading(true),
    [linkTagsNumbered.SUCCESS]: setLoading(false),
    [linkTagsNumbered.FAILURE]: setLoading(false),
  },
  {
    editions: [],
    editionTypes: [],
    edition: EditionDefault,
    loading: false,
  },
);

export default editionsReducer;
