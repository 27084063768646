import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  getClients,
  createClient,
  getClient,
  clearClient,
  updateClient,
  getTableClients,
} from './actions';
import { setLoading } from '../../utils/immer';
import { ClientDefault } from './records';

const clientsReducer = handleActions(
  {
    // GET CLIENT
    [getClient.REQUEST]: setLoading(true),
    [getClient.SUCCESS]: produce((draft, { payload: { client } }) => {
      draft.client = client;
      draft.loading = false;
    }),
    [getClient.FAILURE]: setLoading(false),
    // CREATE CLIENT
    [createClient.REQUEST]: setLoading(true),
    [createClient.SUCCESS]: setLoading(false),
    [createClient.FAILURE]: setLoading(false),
    // UPDATE CLIENT
    [updateClient.REQUEST]: setLoading(true),
    [updateClient.SUCCESS]: produce((draft, { payload: { client } }) => {
      draft.client = client;
      draft.loading = false;
    }),
    [updateClient.FAILURE]: setLoading(false),
    // GET CLIENTS
    [getClients.REQUEST]: setLoading(true),
    [getClients.SUCCESS]: produce((draft, { payload: { clients } }) => {
      draft.clients = clients;
      draft.loading = false;
    }),
    // GET TABLE CLIENTS
    [getTableClients.REQUEST]: setLoading(true),
    [getTableClients.SUCCESS]: produce((draft, { payload: { clients } }) => {
      draft.clients = clients;
      draft.loading = false;
    }),
    [getTableClients.FAILURE]: setLoading(false),
    // CLEAR CLIENT
    [clearClient.SUCCESS]: produce((draft) => {
      draft.client = ClientDefault;
    }),
  },
  {
    client: ClientDefault,
    clients: [],
    loading: false,
  },
);

export default clientsReducer;
