import { call, fork, put, take } from 'redux-saga/effects';
import { getAvailableRange } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetAvailableRange() {
  while (true) {
    const {
      payload: { batch, count, resolve },
    } = yield take(getAvailableRange.REQUEST);

    yield fork(requestGetAvailableRange, batch, count, resolve);
  }
}

function* requestGetAvailableRange(batch, count, resolve) {
  try {
    const params = { batch, count };

    const { data: range } = yield call(Api.batchesAvailableRange.get, params);
    resolve(range);
    console.log('requestGetAvailableRange: ', range);
    yield put(getAvailableRange.success());
  } catch (error) {
    resolve(error.response.data);
    yield put(getAvailableRange.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetAvailableRange;
