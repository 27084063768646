import { createRoutine } from 'redux-saga-routines';

export const checkResetToken = createRoutine('CHECK_RESET_TOKEN', {
  request: (token) => ({ token }),
  success: (token) => ({
    token,
  }),
});

export const resetCheck = createRoutine('RESET_CHECK', {
  request: (email, resolve) => ({ email, resolve }),
});

export const resetPassword = createRoutine('RESET_PASSWORD', {
  request: (values, resolve) => ({
    values,
    resolve,
  }),
});

export const clearToken = createRoutine('CLEAR_TOKEN');
