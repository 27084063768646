import { ClientDefault } from '../Clients/records';

export const TagDefault = {
  start_tag_print_id: '',
  count: null,
  client: ClientDefault,
  end_tag_print_id: '',
};

export const InfoDefault = {
  total: '',
  assigned: '',
  available: '',
};
