import { call, put, takeEvery } from 'redux-saga/effects';
import { getEditionTypes } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchGetEditionTypes() {
  yield takeEvery(getEditionTypes.REQUEST, requestGetEditionTypes);
}

function* requestGetEditionTypes({ payload: { search } }) {
  try {
    const { data: editionTypes } = yield call(Api.editionTypes.getAll, search);

    console.log('requestGetEditionTypes response: ', editionTypes);
    yield put(getEditionTypes.success(editionTypes.results));
  } catch (error) {
    yield put(getEditionTypes.failure());
    yield put(addError(error));
  }
}

export default watchGetEditionTypes;
