import { call, put, takeEvery } from 'redux-saga/effects';
import { linkTagsNumbered } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchLinkTagsNumbered() {
  yield takeEvery(linkTagsNumbered.REQUEST, requestLinkTagsNumbered);
}

function* requestLinkTagsNumbered({ payload: { values, resolve } }) {
  try {
    const { data } = yield call(Api.clientTags.linkNumbered, values);
    console.log('requestLinkTagsNumbered: ', data);

    resolve();

    yield put(linkTagsNumbered.success());
  } catch (error) {
    resolve(error?.response?.data);
    yield put(linkTagsNumbered.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchLinkTagsNumbered;
