import { call, fork, put, take } from 'redux-saga/effects';
import { createEditionType } from '../actions';
import Api from '../../../api/Api';

import { addError } from '../../Notifications/actions';

function* watchCreateClient() {
  while (true) {
    const {
      payload: { editionType, resolve },
    } = yield take(createEditionType.REQUEST);
    yield fork(requestCreateClient, editionType, resolve);
  }
}

function* requestCreateClient(editionType, resolve) {
  try {
    const { data: createdEditionType } = yield call(
      Api.editionTypes.post,
      editionType,
    );

    resolve();
    console.log('requestCreateEditionType: ', createdEditionType);
    yield put(createEditionType.success(createdEditionType));
  } catch (error) {
    resolve(error.response.data);
    yield put(createEditionType.failure());
    yield put(addError(error));
  }
}

export default watchCreateClient;
