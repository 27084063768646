import { call, fork, put, take } from 'redux-saga/effects';
import { createLvUser } from '../actions';
import Api from '../../../api/Api';

import { addError } from '../../Notifications/actions';

function* watchCreateLvUser() {
  while (true) {
    const {
      payload: { user, resolve },
    } = yield take(createLvUser.REQUEST);
    yield fork(requestCreateLvUser, user, resolve);
  }
}

function* requestCreateLvUser(user, resolve) {
  try {
    const { data: createdUser } = yield call(Api.lvUser.post, user);

    resolve();
    console.log('requestCreateLvUser: ', createdUser);
    yield put(createLvUser.success(createdUser));
  } catch (error) {
    resolve(error.response.data);
    yield put(createLvUser.failure());
    yield put(addError(error));
  }
}

export default watchCreateLvUser;
