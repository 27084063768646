import { call, fork, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getEditionType } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetEditionType() {
  while (true) {
    const {
      payload: { id },
    } = yield take(getEditionType.REQUEST);

    yield fork(requestGetEditionType, id);
  }
}

function* requestGetEditionType(id) {
  try {
    const { data: editionType } = yield call(Api.editionTypes.get, id);

    console.log('requestGetEditionType: ', editionType);
    yield put(getEditionType.success(editionType));
  } catch (error) {
    yield put(getEditionType.failure());
    yield put(addError(error));
    yield put(push('/'));
  }
}
/* eslint-enable no-console */

export default watchGetEditionType;
