export const API_URL = '/api/v1';

/**
 * Interval of notification appearance
 */
export const NOTIFICATION_TIMEOUT = 3000;

export const TAGS_STATUSES = {
  UNASSIGNED: 0,
  ASSIGNED: 1,
  REGISTERED: 2,
  LINKED: 3,
};

export const TAGS_STATUSES_LOOKUP = {
  0: {
    value: 0,
    meta: { color: 'unassigned' },
    display_name: 'Not Assigned',
  },
  1: {
    value: 1,
    meta: { color: 'assigned' },
    display_name: 'Not Linked',
  },
  2: {
    value: 2,
    meta: { color: 'registered' },
    display_name: 'Registered',
  },
  3: {
    value: 3,
    meta: { color: 'linked' },
    display_name: 'Linked',
  },
};

export const TAGS_STATUSES_ARRAY = [
  {
    value: 0,
    meta: { color: 'unset' },
  },
  {
    value: 1,
    meta: { color: 'assigned' },
  },
  {
    value: 2,
    meta: { color: 'registered' },
  },
  {
    value: 3,
    meta: { color: 'linked' },
  },
];

export const USER_TYPES_ARRAY = [
  {
    value: 0,
    name: 'Content Manager',
  },
  {
    value: 1,
    name: 'User Administrator',
  },
];

export const USER_TYPES_LOOKUP = {
  0: {
    value: 0,
    name: 'Content Manager',
  },
  1: {
    value: 1,
    name: 'User Administrator',
  },
};

export const TAG_TYPES = {
  BRAND: 0,
  ARTWORK: 1,
  EDITION: 2,
};

export const TAG_TYPES_LOOKUP = {
  0: {
    value: 0,
    display_name: 'Brand',
  },
  1: {
    value: 1,
    display_name: 'Artwork',
  },
  2: {
    value: 2,
    display_name: 'Edition',
  },
};

export const TAG_TYPES_ARRAY = [
  {
    value: 0,
    display_name: 'Brand',
  },
  {
    value: 1,
    display_name: 'Artwork',
  },
  {
    value: 2,
    display_name: 'Edition',
  },
];

export const PERMISSIONS = {
  ARTWORK: {
    VIEW: 'artwork.view_artwork',
    ADD: 'artwork.add_artwork',
    CHANGE: 'artwork.change_artwork',
    DELETE: 'artwork.delete_artwork',
  },
  EDITION: {
    VIEW: 'artwork.view_edition',
    ADD: 'artwork.add_edition',
    CHANGE: 'artwork.change_edition',
    DELETE: 'artwork.delete_edition',
  },
  EDITION_TYPE: {
    VIEW: 'artwork.can_access_edition_types',
  },
  MEDIA_TYPE: {
    VIEW: 'artwork.can_access_media_types',
  },
  TAG: {
    VIEW: 'tag.view_tag',
    ADD: 'tag.add_tag',
    ASSIGNMENT: {
      VIEW: 'tag.view_assignment',
      ADD: 'tag.add_assignment',
    },
  },
  ARTIST: {
    VIEW: 'artwork.view_artist',
    ADD: 'artwork.add_artist',
    CHANGE: 'artwork.change_artist',
    DELETE: 'artwork.delete_artist',
  },
  USER: {
    DASHBOARD: {
      VIEW: 'user.can_see_client_dashboard',
    },
    CLIENT: {
      VIEW: 'user.view_clientuser',
      ADD: 'user.add_clientuser',
      CHANGE: 'user.change_clientuser',
      DELETE: 'user.delete_clientuser',
    },
    LV: {
      VIEW: 'user.view_lvuser',
      ADD: 'user.add_lvuser',
      CHANGE: 'user.change_lvuser',
      DELETE: 'user.delete_lvuser',
    },
    CUSTOMER: {
      VIEW: 'user.view_customeruser',
    },
  },
  USERS: {
    CLIENT_ADMIN: {
      VIEW: 'user.can_edit_own_client_users',
    },
    LV_ADMIN: {
      VIEW: 'user.can_edit_all_client_users',
    },
  },
  LV_USER: {
    DASHBOARD: {
      VIEW: 'user.can_see_lv_dashboard',
    },
  },
  CLIENT: {
    VIEW: 'client.view_client',
    ADD: 'client.add_client',
    CHANGE: 'client.change_client',
    DELETE: 'client.delete_client',
  },
  CLIENT_TAGS: {
    VIEW: 'tag.can_view_client_tags',
    UNLINK: 'tag.can_unlink_tags',
  },
  SETTINGS: {
    BRAND: { EDIT: 'user.can_edit_own_client' },
  },
};
