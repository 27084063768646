import { call, fork, put, take } from 'redux-saga/effects';
import { getTagEnd } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetTagEnd() {
  while (true) {
    const {
      payload: { params, resolve },
    } = yield take(getTagEnd.REQUEST);

    yield fork(requestGetTagEnd, params, resolve);
  }
}

function* requestGetTagEnd(params, resolve) {
  try {
    const {
      data: { end_tag_print_id, start_tag_print_id },
    } = yield call(Api.assignment.getEnd, params);
    resolve({ end: end_tag_print_id, start: start_tag_print_id });
    console.log('requestGetTagEnd: ', end_tag_print_id, start_tag_print_id);
    yield put(getTagEnd.success());
  } catch (error) {
    yield put(getTagEnd.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetTagEnd;
