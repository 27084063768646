import { createSelector } from 'reselect';
import { HomeRounded } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { matchPath } from 'react-router-dom';
import routerMessages from './messages';
import { usersTabs } from '../Users/List/routes';
import { artworkTabs } from '../Artworks/List/components/routes';
import { settingsTabs } from '../Settings/routes';
import { tagsTabs } from '../TagsAssignment/List/components/routes';
import { reportsTabs } from '../Reports/routes';

const selectGlobal = (state) => state.global;

const selectRouter = (state) => state.router;

const makeSelectLoading = () =>
  createSelector(selectGlobal, (globalState) => globalState.loading);

const makeSelectLocation = () =>
  createSelector(selectRouter, (routerState) => routerState.location);

const makeSelectBreadcrumbs = () =>
  createSelector(
    (state) => state,
    (state) => {
      const breadcrumbs = [{ pathname: '/', content: <HomeRounded /> }];

      state.router.location.pathname
        .replace(/\/$/, '')
        .split('/')
        .reduce((previous, current) => {
          const pathSection = `${previous}/${current}`;

          [
            ...usersTabs,
            ...artworkTabs,
            ...settingsTabs,
            ...tagsTabs,
            ...reportsTabs,
            ...state.global.routes,
          ]
            .filter((route) => !route.breadcrumbsHidden)
            .some(({ id, path }) => {
              const match = matchPath(pathSection, { exact: true, path });

              if (match) {
                const obj = {
                  artists: state.artists.artist.name || '',
                  artworks: state.artworks.artwork.name || '',
                  editions:
                    (state.editions.edition.artwork.name &&
                      state.editions.edition.edition_type.name &&
                      `${state.editions.edition.artwork.name} (${state.editions.edition.edition_type.name})`) ||
                    '',
                  clients: state.clients.client.name || '',
                  usersclientusers:
                    (state.users.user.first_name &&
                      state.users.user.last_name &&
                      `${state.users.user.first_name} ${state.users.user.last_name}`) ||
                    (state.clientUsers.user.first_name &&
                      state.clientUsers.user.last_name &&
                      `${state.clientUsers.user.first_name} ${state.clientUsers.user.last_name}`) ||
                    '',
                  userslvusers:
                    (state.lvUsers.user.first_name &&
                      state.lvUsers.user.last_name &&
                      `${state.lvUsers.user.first_name} ${state.lvUsers.user.last_name}`) ||
                    '',
                  settingseditiontypes:
                    state.editionTypes.editionType.name || '',
                  settingsmediatypes: state.mediaTypes.mediaType.name || '',
                  tagsassignment: state.clients.client.name || '',
                };

                const name = obj[previous.replace(/[/-]/g, '')];

                if (name) {
                  breadcrumbs.push({
                    pathname: pathSection,
                    content: name,
                  });
                } else if (routerMessages[`${id}Route`]) {
                  breadcrumbs.push({
                    pathname: pathSection,
                    content: (
                      <FormattedMessage {...routerMessages[`${id}Route`]} />
                    ),
                  });
                }
                return true;
              }
              return false;
            });
          return pathSection;
        });
      return breadcrumbs;
    },
  );

const makeSelectSearch = () =>
  createSelector(selectRouter, (routerState) => routerState.location.search);

const makeSelectQuery = () =>
  createSelector(selectGlobal, (globalState) => globalState.query);

const makeSelectMobileOpen = () =>
  createSelector(selectGlobal, (globalState) => globalState.mobileOpen);

const makeSelectMiniActive = () =>
  createSelector(selectGlobal, (globalState) => globalState.miniActive);

const makeSelectBlockRoute = () =>
  createSelector(selectGlobal, (globalState) => globalState.blockRoute);

const makeSelectOpenApiSchema = () =>
  createSelector(selectGlobal, (globalState) => globalState.schema);

const makeSelectFirstRun = () =>
  createSelector(selectGlobal, (globalState) => globalState.firstRun);

const makeSelectRoutes = () =>
  createSelector(
    (state) => state,
    (state) => state.global.routes,
  );

const checkUserPermissionsOnRoute = (route, state) => {
  const routePermissions = route.permissions;
  const userPermissions = state.login.userInfo.permissions;

  if (routePermissions && userPermissions) {
    return !!routePermissions.find((routePermission) =>
      userPermissions.includes(routePermission),
    );
  }

  return true;
};

const makeSelectSidebarRoutes = () =>
  createSelector(
    (state) => state,
    (state) =>
      state.global.routes.filter(
        (route) =>
          route.sidebarLink && checkUserPermissionsOnRoute(route, state),
      ),
  );

const userIsAuthenticatedSelector = () =>
  createSelector(
    (state) => state,
    (state) => !!state.login.userInfo.email,
  );

const userIsNotAuthenticatedSelector = () =>
  createSelector(
    (state) => state,
    (state) => !state.login.userInfo.email,
  );

const userHasNoPermissionsSelector = () =>
  createSelector(
    (state) => state,
    (state) => {
      const appRoutes = state.global.routes;
      const allRoutes = [...settingsTabs, ...appRoutes];
      const currentLocation = state.router.location.pathname;

      const matchedRoute = allRoutes.find(
        (route) =>
          route.path &&
          matchPath(currentLocation, { path: route.path, exact: route.exact }),
      );

      if (matchedRoute) {
        return checkUserPermissionsOnRoute(matchedRoute, state);
      }

      return true;
    },
  );

export {
  selectGlobal,
  makeSelectLoading,
  makeSelectLocation,
  makeSelectBreadcrumbs,
  makeSelectSearch,
  makeSelectMobileOpen,
  makeSelectMiniActive,
  makeSelectBlockRoute,
  makeSelectRoutes,
  makeSelectSidebarRoutes,
  userIsAuthenticatedSelector,
  userIsNotAuthenticatedSelector,
  userHasNoPermissionsSelector,
  makeSelectQuery,
  makeSelectOpenApiSchema,
  makeSelectFirstRun,
};
