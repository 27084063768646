import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  clearInfo,
  clearError,
  clearWarning,
  clearSuccess,
  addNotification,
  removeNotification,
  clearNotification,
  addOptions,
  clearOptions,
} from './actions';

const notificationsReducer = handleActions(
  {
    [addOptions.SUCCESS]: produce(
      (draft, { payload: { autoHideDuration, maxSnack } }) => {
        draft.options.autoHideDuration = autoHideDuration;
        draft.options.maxSnack = maxSnack;
      },
    ),
    [clearOptions.SUCCESS]: produce((draft) => {
      draft.options.autoHideDuration = 0;
      draft.options.maxSnack = 0;
    }),
    [addNotification]: produce((draft, { payload: { notification } }) => {
      draft.notifications.push(notification);
    }),
    [removeNotification]: produce((draft, { payload: { key } }) => {
      draft.notifications = draft.notifications.filter(
        (current) => current.key !== key,
      );
    }),
    [clearNotification.SUCCESS]: produce((draft) => {
      draft.notifications = [];
    }),
    [clearInfo]: produce((draft) => {
      draft.notifications = draft.notifications.filter(
        (notification) => notification.variant !== 'info',
      );
    }),
    [clearError]: produce((draft) => {
      draft.notifications = draft.notifications.filter(
        (notification) => notification.variant !== 'error',
      );
    }),
    [clearWarning]: produce((draft) => {
      draft.notifications = draft.notifications.filter(
        (notification) => notification.variant !== 'warning',
      );
    }),
    [clearSuccess]: produce((draft) => {
      draft.notifications = draft.notifications.filter(
        (notification) => notification.variant !== 'success',
      );
    }),
  },
  {
    notifications: [],
    options: { autoHideDuration: 0, maxSnack: 0 },
  },
);

export default notificationsReducer;
