import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  loadingStart,
  loadingEnd,
  setMobileOpen,
  setMiniActive,
  setBlockRoute,
  setQueryParams,
  setQueryStatus,
  getOpenApiSchema,
  setFirstRun,
} from './actions';
import { routes } from './routes';

const appReducer = handleActions(
  {
    [loadingStart]: produce((draft) => {
      draft.loading = true;
    }),
    [loadingEnd]: produce((draft) => {
      draft.loading = false;
    }),
    [setMobileOpen.REQUEST]: produce((draft, { payload: { value } }) => {
      draft.mobileOpen = value;
    }),
    [setMiniActive.REQUEST]: produce((draft, { payload: { value } }) => {
      draft.miniActive = value;
    }),
    [setBlockRoute.REQUEST]: produce((draft, { payload: { value } }) => {
      draft.blockRoute = value;
    }),
    [setQueryParams.SUCCESS]: produce((draft, { payload: { params } }) => {
      draft.query.params = params;
    }),
    [setQueryStatus.REQUEST]: produce((draft, { payload: { value } }) => {
      draft.query.isUse = value;
    }),
    [getOpenApiSchema.SUCCESS]: produce((draft, { payload: { schema } }) => {
      draft.schema = schema;
    }),
    [setFirstRun.SUCCESS]: produce((draft) => {
      draft.firstRun = false;
    }),
  },
  {
    loading: false,
    error: false,
    mobileOpen: false,
    miniActive: false,
    blockRoute: true,
    query: {
      params: {},
      isUse: false,
    },
    firstRun: true,
    schema: {},
    routes,
  },
);

export default appReducer;
