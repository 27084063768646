// Works only with query from Material-table lib
const getParamsFromQuery = (query, fields) => {
  const params = {};
  if (query.orderBy && query.orderDirection) {
    if (Array.isArray(fields[query.orderBy.field])) {
      params.order_by =
        query.orderDirection === 'asc'
          ? fields[query.orderBy.field].join(',')
          : fields[query.orderBy.field].map((item) => `-${item}`).join(',');
    } else {
      params.order_by =
        query.orderDirection === 'asc'
          ? fields[query.orderBy.field]
          : `-${fields[query.orderBy.field]}`;
    }
  }
  if (query.orderBy === 'artist_name_order,name') {
    params.order_by = query.orderBy;
  }
  if (query.pageSize) {
    params.limit = query.pageSize;
  }
  if (query.search && query.search !== '') {
    params.search = query.search;
  }
  if ((query.page || query.page === 0) && query.pageSize) {
    params.offset = query.page * query.pageSize;
  }

  return params;
};

export default getParamsFromQuery;
