import { call, put, takeEvery, all } from 'redux-saga/effects';
import { deleteArtists } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchDeleteArtists() {
  yield takeEvery(deleteArtists.REQUEST, requestDeleteArtists);
}

function* requestDeleteArtists({ payload: { artists, resolve } }) {
  try {
    const response = yield all(
      artists.map((artist) => call(Api.artist.delete, artist.id)),
    );
    resolve();
    console.log('requestDeleteArtists response: ', response);
    yield put(deleteArtists.success(artists));
  } catch (error) {
    yield put(deleteArtists.failure());
    yield put(addError(error));
  }
}

export default watchDeleteArtists;
