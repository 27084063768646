import { createRoutine } from 'redux-saga-routines';

export const getTableUsers = createRoutine('GET_TABLE_USERS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (users) => ({
    users,
  }),
});

export const getUsers = createRoutine('GET_USERS', {
  success: (users) => ({
    users,
  }),
});

export const deleteUsers = createRoutine('DELETE_USERS', {
  request: (users, resolve) => ({
    users,
    resolve,
  }),
  success: (users) => ({
    users,
  }),
});

export const getUser = createRoutine('GET_USER', {
  request: (id) => ({
    id,
  }),
  success: (user) => ({
    user,
  }),
});

export const createUser = createRoutine('CREATE_USER', {
  request: (user, resolve) => ({
    user,
    resolve,
  }),
  success: (user) => ({
    user,
  }),
});

export const updateUser = createRoutine('UPDATE_USER', {
  request: (user, resolve) => ({
    user,
    resolve,
  }),
  success: (user) => ({
    user,
  }),
});

export const clearUser = createRoutine('CLEAR_USER');

export const getCustomerUsers = createRoutine('GET_CUSTOMER_USERS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (users) => ({
    users,
  }),
});

export const getSubscribers = createRoutine('GET_SUBSCRIBERS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (subscribers) => ({
    subscribers,
  }),
});

export const downloadSubscribers = createRoutine('DOWNLOAD_SUBSCRIBERS');
