import { call, fork, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getEdition } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetEdition() {
  while (true) {
    const {
      payload: { id, resolve },
    } = yield take(getEdition.REQUEST);

    yield fork(requestGetEdition, id, resolve);
  }
}

function* requestGetEdition(id, resolve) {
  try {
    const { data: edition } = yield call(Api.edition.get, id);

    resolve(edition);
    console.log('requestGetEdition: ', edition);
    yield put(getEdition.success(edition));
  } catch (error) {
    yield put(getEdition.failure());
    yield put(addError(error));
    yield put(push('/'));
  }
}
/* eslint-enable no-console */

export default watchGetEdition;
