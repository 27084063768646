import { PERMISSIONS } from '../../constants';

export const settingsTabs = [
  {
    id: 'settings',
    path: '/settings',
    exact: true,
    permissions: [PERMISSIONS.EDITION_TYPE.VIEW],
  },
  {
    id: 'settingsEditionTypesList',
    path: '/settings/edition-types',
    exact: true,
    permissions: [PERMISSIONS.EDITION_TYPE.VIEW],
  },
  {
    id: 'settingsEditionTypesAdd',
    path: '/settings/edition-types/new',
    exact: true,
    permissions: [PERMISSIONS.EDITION_TYPE.VIEW],
  },
  {
    id: 'settingsEditionTypesView',
    path: '/settings/edition-types/:editionTypeId',
    exact: true,
    permissions: [PERMISSIONS.EDITION_TYPE.VIEW],
  },
  {
    id: 'settingsMediaTypesList',
    path: '/settings/media-types',
    exact: true,
    permissions: [PERMISSIONS.MEDIA_TYPE.VIEW],
  },
  {
    id: 'settingsMediaTypesAdd',
    path: '/settings/media-types/new',
    exact: true,
    permissions: [PERMISSIONS.MEDIA_TYPE.VIEW],
  },
  {
    id: 'settingsMediaTypesView',
    path: '/settings/media-types/:mediaTypeId',
    exact: true,
    permissions: [PERMISSIONS.MEDIA_TYPE.VIEW],
  },
  {
    id: 'settingsBrand',
    path: '/settings/brand',
    exact: true,
    permissions: [PERMISSIONS.SETTINGS.BRAND.EDIT],
  },
];
