import { call, fork, put, take } from 'redux-saga/effects';
import { getRemainingTags } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetRemainingTags() {
  while (true) {
    const {
      payload: { values, resolve },
    } = yield take(getRemainingTags.REQUEST);

    yield fork(requestGetRemainingTags, values, resolve);
  }
}

function* requestGetRemainingTags(values, resolve) {
  try {
    const {
      data: { total_tags },
    } = yield call(Api.remainingTags.post, values);
    resolve(total_tags);
    console.log('requestGetRemainingTags: ', total_tags);
    yield put(getRemainingTags.success());
  } catch (error) {
    resolve('');
    yield put(getRemainingTags.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetRemainingTags;
