import { createRoutine } from 'redux-saga-routines';

export const getTableEditionTypes = createRoutine('GET_TABLE_EDITION_TYPES', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (editionTypes) => ({
    editionTypes,
  }),
});

export const createEditionType = createRoutine('CREATE_EDITION_TYPE', {
  request: (editionType, resolve) => ({
    editionType,
    resolve,
  }),
  success: (editionType) => ({
    editionType,
  }),
});

export const getEditionType = createRoutine('GET_EDITION_TYPE', {
  request: (id) => ({
    id,
  }),
  success: (editionType) => ({
    editionType,
  }),
});

export const updateEditionType = createRoutine('UPDATE_EDITION_TYPE', {
  request: (editionType, resolve) => ({
    editionType,
    resolve,
  }),
  success: (editionType) => ({
    editionType,
  }),
});

export const clearEditionType = createRoutine('CLEAR_EDITION_TYPE');
