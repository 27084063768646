import { fork, all } from 'redux-saga/effects';
import watchGetBrand from './sagaWatchers/getBrand';
import watchUpdateBrand from './sagaWatchers/updateBrand';

export default function* defaultSaga() {
  yield all([
    // Brands
    fork(watchGetBrand),
    fork(watchUpdateBrand),
  ]);
}
