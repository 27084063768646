export const EditionDefault = {
  artwork: {},
  edition_type: {},
  media_type: {},
  year: '',
  image: null,
  certificate_number: null,
  height: null,
  width: null,
  depth: null,
  art_piece_count: null,
};
