import { call, put, takeEvery } from 'redux-saga/effects';
import { getUsers } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

function* watchGetUsers() {
  yield takeEvery(getUsers.REQUEST, requestGetUsers);
}

function* requestGetUsers() {
  try {
    const { data: clients } = yield call(Api.user.getAll);

    console.log('requestGetUsers response: ', clients);
    yield put(getUsers.success(clients.results));
  } catch (error) {
    yield put(getUsers.failure());
    yield put(addError(error));
  }
}

export default watchGetUsers;
