import { fork, all } from 'redux-saga/effects';
import watchCreateLvUser from './sagaWatchers/createLvUser';
import watchGetLvUsers from './sagaWatchers/getLvUsers';
import watchDeleteLvUsers from './sagaWatchers/deleteLvUsers';
import watchGetLvUser from './sagaWatchers/getLvUser';
import watchUpdateLvUser from './sagaWatchers/updateLvUser';
import watchClearLvUser from './sagaWatchers/clearLvUser';

export default function* defaultSaga() {
  yield all([
    // Users
    fork(watchCreateLvUser),
    fork(watchGetLvUsers),
    fork(watchDeleteLvUsers),
    fork(watchGetLvUser),
    fork(watchUpdateLvUser),
    fork(watchClearLvUser),
  ]);
}
