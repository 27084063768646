import { call, fork, put, take } from 'redux-saga/effects';
import { updateMediaType } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchUpdateMediaType() {
  while (true) {
    const {
      payload: { mediaType, resolve },
    } = yield take(updateMediaType.REQUEST);

    yield fork(requestUpdateMediaType, mediaType, resolve);
  }
}

function* requestUpdateMediaType(mediaType, resolve) {
  try {
    const { data: updatedMediaType } = yield call(
      Api.mediaTypes.patch,
      mediaType,
    );

    resolve();
    console.log('requestUpdateMediaType: ', updatedMediaType);
    yield put(updateMediaType.success(updatedMediaType));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateMediaType.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchUpdateMediaType;
