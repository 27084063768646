import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getTableEditions } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';
import { setQueryStatus, setQueryParams } from '../../App/actions';
import { makeSelectQuery } from '../../App/selectors';

const columns = {
  artwork: 'artwork__name',
  edition_type: 'edition_type__name',
  certificate_number: 'certificate_number',
  size: 'dimensions__height,dimensions__width',
  total_linked_tags_number: 'total_linked_tags_number',
  registered_number: 'registered_number',
};

/* eslint-disable no-console */
function* watchGetTableEditions() {
  yield takeEvery(getTableEditions.REQUEST, requestGetTableEditions);
}

function* requestGetTableEditions({
  payload: { query, resolve, reject, setQuery },
}) {
  try {
    const stateQuery = yield select(makeSelectQuery());

    if (stateQuery.isUse && Object.keys(stateQuery.params).length !== 0) {
      query = stateQuery.params;
      yield put(setQueryStatus.request(false));
    } else if (setQuery) {
      yield put(setQueryParams.request(query));
    }

    const params = getParamsFromQuery(query, columns);

    const { data: editions } = yield call(Api.edition.getAll, params);

    resolve({
      data: editions.results,
      page: query.page,
      totalCount: editions.count,
    });
    console.log('requestGetTableEditions response: ', editions);
    yield put(getTableEditions.success(editions.results));
  } catch (error) {
    resolve({
      data: [],
      page: 0,
      totalCount: 0,
    });
    yield put(getTableEditions.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetTableEditions;
