import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getSubscribers } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';
import { makeSelectQuery } from '../../App/selectors';
import { setQueryParams, setQueryStatus } from '../../App/actions';

const columns = {
  email: 'email',
  time: 'time',
};

function* watchGetSubscribers() {
  yield takeEvery(getSubscribers.REQUEST, requestGetSubscribers);
}

function* requestGetSubscribers({
  payload: { query, resolve, reject, setQuery },
}) {
  try {
    const stateQuery = yield select(makeSelectQuery());

    if (stateQuery.isUse && Object.keys(stateQuery.params).length !== 0) {
      query = stateQuery.params;
      yield put(setQueryStatus.request(false));
    } else if (setQuery) {
      yield put(setQueryParams.request(query));
    }
    const params = getParamsFromQuery(query, columns);

    const { data: subscribers } = yield call(Api.subscribers.getAll, params);

    resolve({
      data: subscribers.results,
      page: query.page,
      totalCount: subscribers.count,
    });
    console.log('requestGetSubscribers response: ', subscribers);
    yield put(getSubscribers.success(subscribers.results));
  } catch (error) {
    resolve({
      data: [],
      page: 0,
      totalCount: 0,
    });
    yield put(getSubscribers.failure());
    yield put(addError(error));
  }
}

export default watchGetSubscribers;
