import { createRoutine } from 'redux-saga-routines';

export const getTableClientTags = createRoutine('GET_TABLE_CLIENT_TAGS', {
  request: (query, resolve, reject, setQuery) => ({
    query,
    resolve,
    reject,
    setQuery,
  }),
  success: (tags) => ({
    tags,
  }),
});

export const unlinkClientTags = createRoutine('UNLINK_CLIENT_TAGS', {
  request: (tag, resolve) => ({
    tag,
    resolve,
  }),
});

export const getAvailablePrintIds = createRoutine('GET_AVAILABLE_PRINT_IDS', {
  request: (query, resolve, startPrintId) => ({
    query,
    resolve,
    startPrintId,
  }),
  success: (printIds) => ({
    printIds,
  }),
});
