import { call, fork, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getLvUser } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchGetLvUser() {
  while (true) {
    const {
      payload: { id },
    } = yield take(getLvUser.REQUEST);

    yield fork(requestGetLvUser, id);
  }
}

function* requestGetLvUser(id) {
  try {
    const { data: user } = yield call(Api.lvUser.get, id);

    console.log('requestGetLvUser: ', user);
    yield put(getLvUser.success(user));
  } catch (error) {
    yield put(getLvUser.failure());
    yield put(addError(error));
    yield put(push('/'));
  }
}
/* eslint-enable no-console */

export default watchGetLvUser;
