import { call, put, select, take } from 'redux-saga/effects';
import { resetPassword } from '../actions';
import { makeSelectToken } from '../selectors';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchResetPassword() {
  while (true) {
    const {
      payload: { values, resolve },
    } = yield take(resetPassword.REQUEST);

    yield call(requestSendPassword, values, resolve);
  }
}

function* requestSendPassword(
  { new_password1: newPassword1, new_password2: newPassword2 },
  resolve,
) {
  try {
    const token = yield select(makeSelectToken());

    const { data } = yield call(Api.auth.resetPassword, {
      password1: newPassword1,
      password2: newPassword2,
      key: token,
    });

    resolve();
    console.log('requestSendPassword response: ', data);
    yield put(resetPassword.success());
  } catch (error) {
    resolve(error.response.data);
    yield put(resetPassword.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchResetPassword;
