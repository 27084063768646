import { defineMessages } from 'react-intl';

export const scope = 'app.containers.App';

export default defineMessages({
  artistsRoute: {
    id: `${scope}.route.artists`,
    defaultMessage: 'Artists',
  },
  artistsAddRoute: {
    id: `${scope}.route.artistsAdd`,
    defaultMessage: 'Add Artist',
  },
  artistsViewRoute: {
    id: `${scope}.route.artistsView`,
    defaultMessage: 'Artist',
  },
  artworksListRoute: {
    id: `${scope}.route.artworks`,
    defaultMessage: 'Artwork',
  },
  artworksAddRoute: {
    id: `${scope}.route.artworksAdd`,
    defaultMessage: 'Add Artwork',
  },
  artworksLinkTagsRoute: {
    id: `${scope}.route.artworks.linkTags`,
    defaultMessage: 'Link Tags',
  },
  artworksTagsRoute: {
    id: `${scope}.route.artworks.tags`,
    defaultMessage: 'Tags',
  },
  importsListRoute: {
    id: `${scope}.route.importsList`,
    defaultMessage: 'Imports',
  },
  importsAddRoute: {
    id: `${scope}.route.importsAdd`,
    defaultMessage: 'Add Import',
  },
  editionsRoute: {
    id: `${scope}.route.editions`,
    defaultMessage: 'Editions',
  },
  editionsAddRoute: {
    id: `${scope}.route.editionsAdd`,
    defaultMessage: 'Add Edition',
  },
  editionsViewRoute: {
    id: `${scope}.route.editionsView`,
    defaultMessage: 'Edition',
  },
  editionsLinkTagsRoute: {
    id: `${scope}.route.editionsLinkTags`,
    defaultMessage: 'Link Tags',
  },
  editionsTagsListRoute: {
    id: `${scope}.route.editionsTagsList`,
    defaultMessage: 'Tags',
  },
  clientTagsRoute: {
    id: `${scope}.route.clientTags`,
    defaultMessage: 'Tags',
  },
  tagsRoute: {
    id: `${scope}.route.tags`,
    defaultMessage: 'Tags',
  },
  tagsAssignmentRoute: {
    id: `${scope}.route.tagsAssignment`,
    defaultMessage: 'Management',
  },
  tagsAvailableRoute: {
    id: `${scope}.route.tagsAvailable`,
    defaultMessage: 'Available Tags',
  },
  tagsAvailableAssignRoute: {
    id: `${scope}.route.tagsAvailableAssign`,
    defaultMessage: 'Assign Tags',
  },
  tagsClientRoute: {
    id: `${scope}.route.tagsClient`,
    defaultMessage: 'Client',
  },
  tagsClientAssignRoute: {
    id: `${scope}.route.tagsClientAssign`,
    defaultMessage: 'Assign Tags',
  },
  clientsRoute: {
    id: `${scope}.route.clients`,
    defaultMessage: 'Clients',
  },
  clientsAddRoute: {
    id: `${scope}.route.clientsAdd`,
    defaultMessage: 'Add Client',
  },
  clientsViewRoute: {
    id: `${scope}.route.clientsView`,
    defaultMessage: 'Client',
  },
  editionTypesRoute: {
    id: `${scope}.route.editionTypes`,
    defaultMessage: 'Edition Types',
  },
  editionTypesAddRoute: {
    id: `${scope}.route.editionTypesAdd`,
    defaultMessage: 'Add Edition Type',
  },
  editionTypesViewRoute: {
    id: `${scope}.route.editionTypesView`,
    defaultMessage: 'Edition Type',
  },
  settingsRoute: {
    id: `${scope}.route.settings`,
    defaultMessage: 'Settings',
  },
  settingsEditionTypesListRoute: {
    id: `${scope}.route.settings.editionTypes.list`,
    defaultMessage: 'Edition Types',
  },
  settingsEditionTypesAddRoute: {
    id: `${scope}.route.settings.editionTypes.add`,
    defaultMessage: 'Add Edition Type',
  },
  settingsEditionTypesViewRoute: {
    id: `${scope}.route.settings.editionTypes.view`,
    defaultMessage: 'Edition Type',
  },
  settingsMediaTypesListRoute: {
    id: `${scope}.route.settings.mediaTypes.list`,
    defaultMessage: 'Media Types',
  },
  settingsMediaTypesAddRoute: {
    id: `${scope}.route.settings.mediaTypes.add`,
    defaultMessage: 'Add Media Type',
  },
  settingsMediaTypesViewRoute: {
    id: `${scope}.route.settings.mediaTypes.view`,
    defaultMessage: 'Media Type',
  },
  settingsBrandRoute: {
    id: `${scope}.route.settings.Brands`,
    defaultMessage: 'Brand',
  },
  usersListRoute: {
    id: `${scope}.route.usersList`,
    defaultMessage: 'Users',
  },
  clientUsersAddRoute: {
    id: `${scope}.route.clientUsersAdd`,
    defaultMessage: 'Add User',
  },
  userViewRoute: {
    id: `${scope}.route.userView`,
    defaultMessage: 'User',
  },
  lvUsersListRoute: {
    id: `${scope}.route.lvUsersList`,
    defaultMessage: 'LV Users',
  },
  lvUserViewRoute: {
    id: `${scope}.route.lvUserView`,
    defaultMessage: 'User',
  },
  lvUsersAddRoute: {
    id: `${scope}.route.lvUsersAdd`,
    defaultMessage: 'Add User',
  },
  customersListRoute: {
    id: `${scope}.route.customersList`,
    defaultMessage: 'Customers',
  },
  subscribersListRoute: {
    id: `${scope}.route.subscribersList`,
    defaultMessage: 'Subscribers',
  },
  dashboardRoute: {
    id: `${scope}.route.dashboard`,
    defaultMessage: 'Dashboard',
  },
  reportsRoute: {
    id: `${scope}.route.reports`,
    defaultMessage: 'Reports',
  },
  reportsInventoryRoute: {
    id: `${scope}.route.reportsInventory`,
    defaultMessage: 'Inventory',
  },
  reportsTagUsageRoute: {
    id: `${scope}.route.reportsTagUsage`,
    defaultMessage: 'Tag Usage',
  },
  reportsArtLibraryRoute: {
    id: `${scope}.route.reportsArtLibrary`,
    defaultMessage: 'Art Library',
  },
  reportsContentUsageRoute: {
    id: `${scope}.route.reportsContentUsage`,
    defaultMessage: 'Content Usage',
  },
  loginRoute: {
    id: `${scope}.route.login`,
    defaultMessage: 'Login',
  },
  signupRoute: {
    id: `${scope}.route.signup`,
    defaultMessage: 'Sign Up',
  },
  signupStepRoute: {
    id: `${scope}.route.signupStep`,
    defaultMessage: 'Sign Up Confirm',
  },
  forgotRoute: {
    id: `${scope}.route.forgot`,
    defaultMessage: 'Reset Password',
  },
  forgotConfirmRoute: {
    id: `${scope}.route.forgotConfirm`,
    defaultMessage: 'Reset Password Confirm',
  },
  forbiddenRoute: {
    id: `${scope}.route.forbidden`,
    defaultMessage: 'Forbidden',
  },
  '404Route': {
    id: `${scope}.route.404`,
    defaultMessage: '404',
  },
});
