import { call, fork, put, take } from 'redux-saga/effects';
import { updateEdition } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';

/* eslint-disable no-console */
function* watchUpdateEdition() {
  while (true) {
    const {
      payload: { edition, resolve },
    } = yield take(updateEdition.REQUEST);

    yield fork(requestUpdateEdition, edition, resolve);
  }
}

function* requestUpdateEdition({ image, ...edition }, resolve) {
  try {
    const editionNew = {
      ...((image && typeof image === 'object') || image === null
        ? { image }
        : {}),
    };

    Object.keys(edition).forEach((key) => {
      if (
        key !== 'depth_visible' &&
        key !== 'depth_saved' &&
        key !== 'year_visible' &&
        key !== 'year_saved'
      ) {
        editionNew[key] = edition[key];
      }
    });

    const { data: updatedEdition } = yield call(Api.edition.patch, editionNew);

    resolve();
    console.log('requestUpdateEdition: ', updatedEdition);
    yield put(updateEdition.success(updatedEdition));
  } catch (error) {
    resolve(error.response.data);
    yield put(updateEdition.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchUpdateEdition;
