import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getLvUsers } from '../actions';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import getParamsFromQuery from '../../../utils/getParamsFromQuery';
import { makeSelectQuery } from '../../App/selectors';
import { setQueryParams, setQueryStatus } from '../../App/actions';

const columns = {
  email: 'email',
  first_name: 'first_name',
  last_name: 'last_name',
};

function* watchGetLvUsers() {
  yield takeEvery(getLvUsers.REQUEST, requestGetLvUsers);
}

function* requestGetLvUsers({ payload: { query, resolve, reject, setQuery } }) {
  try {
    const stateQuery = yield select(makeSelectQuery());

    if (stateQuery.isUse && Object.keys(stateQuery.params).length !== 0) {
      query = stateQuery.params;
      yield put(setQueryStatus.request(false));
    } else if (setQuery) {
      yield put(setQueryParams.request(query));
    }
    const params = getParamsFromQuery(query, columns);

    const { data: users } = yield call(Api.lvUser.getAll, params);

    resolve({ data: users.results, page: query.page, totalCount: users.count });
    console.log('requestGetLvUsers response: ', users);
    yield put(getLvUsers.success(users.results));
  } catch (error) {
    resolve({
      data: [],
      page: 0,
      totalCount: 0,
    });
    yield put(getLvUsers.failure());
    yield put(addError(error));
  }
}

export default watchGetLvUsers;
