import { call, fork, put, take } from 'redux-saga/effects';
import Api from '../../../api/Api';
import { addError } from '../../Notifications/actions';
import { getOpenApiSchema, setFirstRun } from '../actions';

/* eslint-disable no-console */
function* watchGetOpenApiSchema() {
  while (true) {
    const {
      payload: { resolve },
    } = yield take(getOpenApiSchema.REQUEST);

    yield fork(requestGetOpenApiSchema, resolve);
  }
}

function* requestGetOpenApiSchema(resolve) {
  try {
    const {
      data: schema,
      headers: { date },
    } = yield call(Api.openApiSchema.get);

    resolve(date);
    console.log('requestGetOpenApiSchema: ', schema);
    yield put(getOpenApiSchema.success(schema?.components?.schemas));
    yield put(setFirstRun.success());
  } catch (error) {
    if (error.response.status !== 401) {
      yield put(setFirstRun.success());
    }
    yield put(getOpenApiSchema.failure());
    yield put(addError(error));
  }
}
/* eslint-enable no-console */

export default watchGetOpenApiSchema;
